import React, { useEffect, useState } from 'react'
import axios from "axios";
import { API, AUTH_HEADER } from "../constants/api.const";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import "../style/agenda.scss";
import { L } from '../langauge/english.lang';
import StatusDisplay from '../component/StatusDisplay';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../services/dateService';

function Admin({privacyMode}) {

  const [agenda, setAgenda] = useState([]);
  const navigate = useNavigate()


  const formatDateForAPI = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  };
  

  // const handleClick = (recording)=>{
  //   navigate('/recording-details', {state : {recording :  recording, isAdmin : true}})
  // }
  const handleClick = (recording) => {
    if (recording.is_reviewed) {
      navigate('/recording-details', {state : {recording :  recording, isAdmin : true}})
    }
  }
  const [selectedDate, setSelectedDate] = useState(dayjs())

  useEffect(() => {
      axios
        .get(API.GET_RECORDINGS + `?date=${formatDateForAPI(selectedDate)}`, AUTH_HEADER)
        .then((res) => {
          if (res && res.data) setAgenda(res.data.data);
        })
        .catch((e) => console.log(e));
  }, [selectedDate]);
  return (
    <div className='agenda admin-component'>
        <div className="left content">
         <div className="head">
         {L.ALL_CONSULTATION}
         {/* <h3 className='date'> {formatDate(selectedDate.toISOString(), {weekday : 'long' })}, {formatDate(selectedDate.toISOString(), {dateStyle : 'medium' })}  </h3> */}
         </div>
         {agenda.length>0?( <div className="list">
            {agenda.map((item) => (
              // <div className="item" onClick={e=>handleClick(item)}>
              <div 
                className={`item ${item.is_reviewed ? 'clickable' : 'not-clickable'}`} 
                onClick={() => handleClick(item)}
                style={{ cursor: item.is_reviewed ? 'pointer' : 'not-allowed' }}
              >

                <div className="seg2">
                <div className='top'>
                <div>
                <div className="name">
                    {privacyMode?item.appointment?.patient?.first_name[0]+(item.appointment?.patient?.last_name?item.appointment?.patient?.last_name[0]:''):item.appointment?.patient?.first_name+" "+item.appointment?.patient?.last_name}
                </div>
                <div className="time">
                    {formatDate(item.appointment?.appointment_time, {
                      dateStyle: "medium",
                    })}
                  </div> <div className="time">
                    {formatDate(item.appointment?.appointment_time, {
                      timeStyle: "short",
                    })}
                  </div>
                  </div>
                <StatusDisplay className='icon' isReviewed={item.is_reviewed}/>
                </div>
                  <div className="desc">
                    
                  {item.description}
                  </div>
                
                 
                </div>
              </div>
            ))}
          </div>):(
            <div className='norecord'> {L.NO_CONSULTATION} </div>
          )}
        
      </div>

      <div className="right">
      <LocalizationProvider dateAdapter={AdapterDayjs} className='cal' >
      <DateCalendar onChange={e=>setSelectedDate(e)} value={selectedDate}/>
    </LocalizationProvider>
      </div>
    </div>
  )
}

export default Admin