import { API_URI } from "../environment";
import { TOKEN_KEY } from "./key.const";

export const API = {
    GET_APPOINTMENTS : API_URI+'/api/consulations/appoinment/',
    POST_APPOINTMENT : API_URI+'/api/consulations/appoinment/',

    GET_RECORDINGS : API_URI+'/api/consulations/recording/',
    POST_RECORDING : API_URI+'/api/consulations/recording/',
    UPDATE_RECORDING : API_URI+'/api/consulations/recording/',

    GET_PROFILE : API_URI+'/api/auth/',
    UPDATE_PROFILE : API_URI+'/api/auth/',

    MICROSOFT_HANDLE_LOGIN : API_URI+'/api/auth/microsoft_handle_login/',
    MICROSOFT_LOGIN : API_URI+'/api/auth/microsoft_login/',


}

const staticToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTg2NDkwLCJpYXQiOjE3MjYzOTQ0OTAsImp0aSI6IjQwNDgzYzNkMTM3ODQ2OGM5ZmIyMGVjMjYyOWQyYTNjIiwidXNlcl9pZCI6OH0.VgD7cWI_lJNF49xLzh62AjveaePFo1bE3SojFLvqHyw';
export const SetAUTH_HEADER = () => {
    AUTH_HEADER =  {headers : {Authorization : 'Bearer ' + sessionStorage.getItem(TOKEN_KEY)  }}

}

 export var AUTH_HEADER =  {headers : {Authorization : 'Bearer ' + sessionStorage.getItem(TOKEN_KEY)  }}
// export var AUTH_HEADER =  {headers : {Authorization : 'Bearer ' + staticToken }}

