// // import React, { useState, useEffect } from 'react';
// // import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// // import Dropdown from 'react-bootstrap/Dropdown';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import CopyAllIcon from '@mui/icons-material/CopyAll';
// // import ContentCutIcon from '@mui/icons-material/ContentCut';
// // import SpellcheckIcon from '@mui/icons-material/Spellcheck';
// // import { L } from '../langauge/english.lang';
// // import TextFieldsIcon from '@mui/icons-material/TextFields';
// // import UndoIcon from '@mui/icons-material/Undo';
// // import axios from 'axios';
// // import { API, AUTH_HEADER } from '../constants/api.const';
// // import { toast } from 'react-toastify';

// // const Step4 = ({setActiveStep, setPage}) => {
// //     const [value, onChange] = useState(new Date());
// //     const [model, setModel] = useState('CHATGPT 4');
// //     const [dropdownOpen, setDropdownOpen] = useState(false);
// //     const [clientNote, setClientNote] = useState("chatgpt")
// //     const [savingData, setSavingData] = useState(false)
// //     const [lastTimeUpdate, setLastTimeUpdate] = useState(new Date())

// //     const location = useLocation()
// //     const {state} = location
// //     console.log(state)

// //     const navigate = useNavigate()
// //     setPage(L.REVIEW_NOTE)
// //     const [modelData, setModelData] = useState(state?.modelData)

// //     const handleModel = (type) => {
// //         setModel(type);
// //         let modelData = state?.modelData
// //         if (modelData) {
// //             if (type === "CHATGPT 4") {
// //                 setClientNote(modelData.gpt4oSummary);
// //             } else if (type === "Custom-Model") {
// //                 setClientNote(modelData.llama3Summary);
// //             } else {
// //                 setClientNote("Here, you can write your summary");
// //             }
// //         }
// //         setDropdownOpen(false);
// //     };

// //     const copyToClipboard = (cut = false) => {
// //         navigator.clipboard.writeText(clientNote).then(() => {
// //             if(cut===true) setClientNote("")
// //         }).catch(err => {
// //         });
// //       };

// //     //   useEffect(()=>{
// //     //    const autoSave = ()=>{
// //     //     setInterval(() => {
// //     //         console.log(savingData)
// //     //             updateData()
// //     //     }, 5000);
// //     //    }

// //     //    autoSave()
// //     //   }, [])

// //       const updateData = ()=>{
// //         // console.log(savingData)
// //         // if(!savingData) return
// //         setLastTimeUpdate(new Date())
// //         const payload = {
// //             consultation_summaries:[
// //                 {
// //                     ai_model_name:"chatgpt_4",
// //                     summary_text:clientNote
// //                 }
// //             ]
// //         }

// //         axios.patch(API.UPDATE_RECORDING + state.recordingId + "/", payload, AUTH_HEADER).then(d => {
// //             setSavingData(false)
// //         }).catch(e => {
// //             // toast.error(e.response.data.error || "Error while updating the recording details")
// //         })
// //       }

// //       if(savingData){
// //         const dt = new Date()

// //         console.log("saving data", dt.getTime()-lastTimeUpdate.getTime())
// //         if(dt.getTime()-lastTimeUpdate.getTime()>3000) updateData()

// //       }
// //     useEffect(() => {
// //         handleModel(model);
// //     }, [model]);

// //     return (

// //             <div>
// //                 {/* <div className='d-flex justify-content-between mb-4 align-items-center'>
// //                     <h4 className='right-col-title '>
// //                         Edit
// //                     </h4>
// //                     <Dropdown
// //                         show={dropdownOpen}
// //                         onToggle={() => setDropdownOpen(!dropdownOpen)}
// //                     >
// //                         <Dropdown.Toggle id="dropdown-basic"   style={{
// //                             backgroundColor : '#7F7F7F',
// //                             border : 'none'
// //                         }}>
// //                             {model}
// //                         </Dropdown.Toggle>
// //                         <Dropdown.Menu >
// //                             <li class="dropdown-item" onClick={() => handleModel("CHATGPT 4")}>CHATGPT 4</li>
// //                             <li class="dropdown-item" onClick={() => handleModel("Custom-Model")}>Custom-Model</li>
// //                             <li class="dropdown-item" onClick={() => handleModel("Write your own")}>Write your own</li>
// //                         </Dropdown.Menu>
// //                     </Dropdown>
// //                 </div> */}
// //                 <div className='tab-wraper'>

// //                         <div style={{
// //                             borderRadius : '8px',
// //                             border : '1px solid #dddddd'
// //                         }} >
// //                             <div style={{
// //                             backgroundColor : '#3667E9bb',
// //                             color : 'white',
// //                             fontSize : '18px',
// //                             padding : '12px 8px',
// //                             textAlign : 'center',
// //                             borderRadius : '8px 8px 0px 0px'
// //                         }}> {L.NOTE} </div>
// //                             <div>
// //                                 <div style={{
// //                                     display : 'flex',
// //                                     alignItems : 'center',
// //                                     justifyContent : 'space-between',
// //                                     padding: "5px 12px",
// //                                     borderBottom : '1px solid #dddddd'
// //                                 }}>
// //                                 <div style={{
// //                                     display : 'flex',
// //                                     gap:'12px',
// //                                     alignItems : 'center',
// //                                     justifyContent : 'left',
// //                                 }}>

// //                                     <UndoIcon  onClick={e=>copyToClipboard(true)}  style={{cursor :'pointer', color : '#000000', margin : '0px 1px', width:'16px', height:'16px'}}/>
// //                                     <UndoIcon  onClick={e=>copyToClipboard(true)}  style={{cursor :'pointer', transform : 'rotateY(180deg)', color : '#000000', margin : '0px 1px', width:'16px', height:'16px'}}/>

// //                                     <select style={{border : 'none', margin : '0px 4px'}}>
// //                                         <option>Sans Sarif</option>
// //                                     </select>
// //                                     <TextFieldsIcon style={{cursor :'pointer', color : '#000000', margin : '0px 3px', width:'16px', height:'16px'}}/>
// //                                     <ContentCutIcon  onClick={e=>copyToClipboard(true)}  style={{cursor :'pointer', color : '#000000', margin : '0px 3px', width:'16px', height:'16px'}}/>
// //                                     <CopyAllIcon onClick={copyToClipboard} style={{cursor :'pointer', color : '#000000', margin : '0px 3px', width:'16px', height:'16px'}}/>
// //                                     {/* <SpellcheckIcon style={{cursor :'pointer', color : '#000000', margin : '0px 3px', width:'16px', height:'16px'}}/> */}
// //                                 </div>

// //                                 {(savingData==true) && (<div style={{color :'blue', fontSize:'14px'}}> {L.SAVING} </div>)}
// //                                 </div>
// //                                 <textarea style={{
// //                                     width:'100%',
// //                                     height : 'calc(100vh - 320px)',
// //                                     resize : 'none',
// //                                     minHeight : '300px',
// //                                     border : 'none',
// //                                     padding : '3px'
// //                                 }}
// //                                  onChange={e=>{setClientNote(e.target.value); setSavingData(true)}} className='content' value={clientNote}>  </textarea>
// //                             </div>
// //                         </div>
// //                 </div>
// //                 <div className='footer'>
// //                 <KeyboardBackspaceIcon  className='footer-button'  onClick={e => {setActiveStep(2); navigate('/home/walkthrough/record', {state : {...state}})}}/>
// //                 <KeyboardBackspaceIcon style={{transform : 'rotateZ(180deg)'}}  className='footer-button' onClick={e => {setActiveStep(3); navigate('/home/walkthrough/wrap-up', {state : {...state, modelData : modelData}})}}/>
// //                 </div>
// //             </div>
// //     )
// // }

// // export default Step4;

// import React, { useState, useEffect } from 'react';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { useLocation, useNavigate } from 'react-router-dom';
// import CopyAllIcon from '@mui/icons-material/CopyAll';
// import ContentCutIcon from '@mui/icons-material/ContentCut';
// import UndoIcon from '@mui/icons-material/Undo';
// import TextFieldsIcon from '@mui/icons-material/TextFields';
// import axios from 'axios';
// import { API, AUTH_HEADER } from '../constants/api.const';
// import { toast } from 'react-toastify';
// import { L } from '../langauge/english.lang';

// const Step4 = ({ setActiveStep, setPage }) => {
//     const [model, setModel] = useState('CHATGPT 4');
//     const [dropdownOpen, setDropdownOpen] = useState(false);
//     const [clientNote, setClientNote] = useState("");
//     const [savingData, setSavingData] = useState(false);
//     const [lastTimeUpdate, setLastTimeUpdate] = useState(new Date());

//     const location = useLocation();
//     const { state } = location;
//     const navigate = useNavigate();
//     setPage(L.REVIEW_NOTE);

//     useEffect(() => {
//         const fetchRecordingData = async () => {
//             try {
//                 const response = await axios.get(`${API.UPDATE_RECORDING}${state.recordingId}/`, AUTH_HEADER);
//                 const summaries = response.data.data.consultation_summary;
//                 const chatGPT4Summary = summaries.find(summary => summary.ai_model_name === "llama3");
//                 if (chatGPT4Summary) {
//                     setClientNote(chatGPT4Summary.summary_text);
//                 } else {
//                     setClientNote("No ChatGPT 4 summary available.");
//                 }
//             } catch (error) {
//                 toast.error("Error fetching recording data");
//                 console.error("Error fetching recording data:", error);
//             }
//         };

//         fetchRecordingData();
//     }, [state.recordingId]);

//     const handleModel = (type) => {
//         setModel(type);
//         // You may want to fetch different summaries based on the selected model
//     };

//     const copyToClipboard = (cut = false) => {
//         navigator.clipboard.writeText(clientNote).then(() => {
//             if (cut === true) setClientNote("");
//         }).catch(err => {
//             console.error("Failed to copy text: ", err);
//         });
//     };

//     const updateData = () => {
//         setLastTimeUpdate(new Date());
//         const payload = {
//             consultation_summaries: [
//                 {
//                     ai_model_name: "llama3",
//                     summary_text: clientNote
//                 }
//             ]
//         };

//         axios.patch(`${API.UPDATE_RECORDING}${state.recordingId}/`, payload, AUTH_HEADER)
//             .then(() => {
//                 setSavingData(false);
//                 toast.success("Data saved successfully");
//             })
//             .catch(e => {
//                 toast.error(e.response?.data?.error || "Error while updating the recording details");
//             });
//     };

//     useEffect(() => {
//         if (savingData) {
//             const dt = new Date();
//             if (dt.getTime() - lastTimeUpdate.getTime() > 3000) updateData();
//         }
//     }, [savingData, lastTimeUpdate]);

//     return (
//         <div>
//             <div className='tab-wraper'>
//                 <div style={{
//                     borderRadius: '8px',
//                     border: '1px solid #dddddd'
//                 }}>
//                     <div style={{
//                         backgroundColor: '#3667E9bb',
//                         color: 'white',
//                         fontSize: '18px',
//                         padding: '12px 8px',
//                         textAlign: 'center',
//                         borderRadius: '8px 8px 0px 0px'
//                     }}>{L.NOTE}</div>
//                     <div>
//                         <div style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'space-between',
//                             padding: "5px 12px",
//                             borderBottom: '1px solid #dddddd'
//                         }}>
//                             <div style={{
//                                 display: 'flex',
//                                 gap: '12px',
//                                 alignItems: 'center',
//                                 justifyContent: 'left',
//                             }}>
//                                 <UndoIcon onClick={() => {}} style={{cursor: 'pointer', color: '#000000', margin: '0px 1px', width: '16px', height: '16px'}}/>
//                                 <UndoIcon onClick={() => {}} style={{cursor: 'pointer', transform: 'rotateY(180deg)', color: '#000000', margin: '0px 1px', width: '16px', height: '16px'}}/>
//                                 <select style={{border: 'none', margin: '0px 4px'}}>
//                                     <option>Sans Serif</option>
//                                 </select>
//                                 <TextFieldsIcon style={{cursor: 'pointer', color: '#000000', margin: '0px 3px', width: '16px', height: '16px'}}/>
//                                 <ContentCutIcon onClick={e => copyToClipboard(true)} style={{cursor: 'pointer', color: '#000000', margin: '0px 3px', width: '16px', height: '16px'}}/>
//                                 <CopyAllIcon onClick={copyToClipboard} style={{cursor: 'pointer', color: '#000000', margin: '0px 3px', width: '16px', height: '16px'}}/>
//                             </div>
//                             {(savingData === true) && (<div style={{color: 'blue', fontSize: '14px'}}>{L.SAVING}</div>)}
//                         </div>
//                         <textarea
//                             style={{
//                                 width: '100%',
//                                 height: 'calc(100vh - 320px)',
//                                 resize: 'none',
//                                 minHeight: '300px',
//                                 border: 'none',
//                                 padding: '3px'
//                             }}
//                             onChange={e => {
//                                 setClientNote(e.target.value);
//                                 setSavingData(true);
//                             }}
//                             className='content'
//                             value={clientNote}
//                         />
//                     </div>
//                 </div>
//             </div>
//             <div className='footer'>
//                 <KeyboardBackspaceIcon className='footer-button' onClick={() => {setActiveStep(2); navigate('/home/walkthrough/record', {state: {...state}})}}/>
//                 <KeyboardBackspaceIcon style={{transform: 'rotateZ(180deg)'}} className='footer-button' onClick={() => {setActiveStep(3); navigate('/home/walkthrough/wrap-up', {state: {...state}})}}/>
//             </div>
//         </div>
//     );
// };

// export default Step4;

import React, { useState, useEffect, useRef } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import UndoIcon from "@mui/icons-material/Undo";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { API, AUTH_HEADER } from "../constants/api.const";
import { toast } from "react-toastify";
import { L } from "../langauge/english.lang";

const Step4 = ({ setActiveStep, setPage }) => {
  const [clientNote, setClientNote] = useState("");
  const [savingData, setSavingData] = useState(false);
  const [lastTimeUpdate, setLastTimeUpdate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [fos, setFos] = useState(12);
  const fontSizeSelector = useRef(null);
  const fontFamilies = [
    "Sans Serif",
    "Arial",
    "Georgia",
    "Times New Roman",
    "Verdana",
    "Trebuchet MS",
    "Courier New",
    "Lucida Sans",
    "Poppins",
    "Playfair Display",
  ];

  const fontSizes = [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26];

  const [selectedFont, setSelectedFont] = useState(fontFamilies[0]);
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  setPage(L.REVIEW_NOTE);

  useEffect(() => {
    const fetchRecordingData = async () => {
      try {
        const response = await axios.get(
          `${API.UPDATE_RECORDING}${state.recordingId}/`,
          AUTH_HEADER
        );
        const summaries = response.data.data.consultation_summary;
        const chatGPT4Summary = summaries.find(
          (summary) => summary.ai_model_name === "llama3"
        );
        if (chatGPT4Summary) {
          setClientNote(chatGPT4Summary.summary_text);
        } else {
          setClientNote("No ChatGPT 4 summary available.");
        }
      } catch (error) {
        toast.error("Error fetching recording data");
        console.error("Error fetching recording data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecordingData();
  }, [state.recordingId]);

  const copyToClipboard = (cut = false) => {
    navigator.clipboard
      .writeText(clientNote)
      .then(() => {
        if (cut === true) setClientNote("");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const updateData = () => {
    setLastTimeUpdate(new Date());
    const payload = {
      consultation_summaries: [
        {
          ai_model_name: "llama3",
          summary_text: clientNote,
        },
      ],
    };

    axios
      .patch(
        `${API.UPDATE_RECORDING}${state.recordingId}/`,
        payload,
        AUTH_HEADER
      )
      .then(() => {
        setSavingData(false);
        // toast.success("Data saved successfully");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.error ||
            "Error while updating the recording details"
        );
      });
  };

  useEffect(() => {
    if (savingData) {
      const dt = new Date();
      // setSavingData(false)
      if (dt.getTime() - lastTimeUpdate.getTime() > 3000) updateData();
    }
  }, [clientNote, lastTimeUpdate]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="tab-wraper">
        <div
          style={{
            borderRadius: "8px",
            border: "1px solid #dddddd",
          }}
        >
          <div
            style={{
              backgroundColor: "#3667E9bb",
              color: "white",
              fontSize: "18px",
              padding: "12px 8px",
              textAlign: "center",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            {L.NOTE}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 12px",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <UndoIcon
                  style={{
                    cursor: "pointer",
                    color: "#000000",
                    margin: "0px 1px",
                    width: "16px",
                    height: "16px",
                  }}
                  onClick={(e) => document.execCommand("undo")}
                />
                <UndoIcon
                  style={{
                    cursor: "pointer",
                    transform: "rotateY(180deg)",
                    color: "#000000",
                    margin: "0px 1px",
                    width: "16px",
                    height: "16px",
                  }}
                  onClick={(e) => document.execCommand("redo")}
                />
                <select
                  style={{ border: "none", margin: "0px 4px" }}
                  value={selectedFont}
                  onChange={(e) => setSelectedFont(e.target.value)}
                >
                  {fontFamilies.map((f) => (
                    <option style={{ fontFamily: f }}> {f} </option>
                  ))}
                </select>
                <div style={{position : 'relative'}}>
                  <TextFieldsIcon
                    style={{
                      cursor: "pointer",
                      color: "#000000",
                      margin: "0px 3px",
                      width: "16px",
                      height: "16px",
                      position : 'absolute',
                      top : '5px'
                    }}
                    onClick={(e) => fontSizeSelector.click()}
                  />
                  <select
                    style={{ border: "none", margin: "0px", width: "22px", opacity: 0 }}
                    ref={fontSizeSelector}
                    value={fos}
                    onChange={(e) => setFos(e.target.value)}
                  >
                    {fontSizes.map((f) => (
                      <option> {f} </option>
                    ))}
                  </select>
                </div>
                <ContentCutIcon
                  onClick={(e) => copyToClipboard(true)}
                  style={{
                    cursor: "pointer",
                    color: "#000000",
                    margin: "0px 3px",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <CopyAllIcon
                  onClick={copyToClipboard}
                  style={{
                    cursor: "pointer",
                    color: "#000000",
                    margin: "0px 3px",
                    width: "16px",
                    height: "16px",
                  }}
                />
              </div>
              {savingData === true && (
                <div style={{ color: "blue", fontSize: "14px" }}>
                  {L.SAVING}
                </div>
              )}
            </div>
            <textarea
              style={{
                width: "100%",
                height: "calc(100vh - 320px)",
                resize: "none",
                minHeight: "300px",
                border: "none",
                padding: "3px",
                fontFamily: selectedFont,
                fontSize: fos + "px",
              }}
              onChange={(e) => {
                setClientNote(e.target.value);
                setSavingData(true);
              }}
              className="content"
              value={clientNote}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <KeyboardBackspaceIcon
          className="footer-button"
          onClick={() => {
            setActiveStep(2);
            navigate("/home/walkthrough/record", { state: { ...state } });
          }}
        />
        <KeyboardBackspaceIcon
          style={{ transform: "rotateZ(180deg)" }}
          className="footer-button"
          onClick={() => {
            setActiveStep(3);
            navigate("/home/walkthrough/wrap-up", { state: { ...state } });
          }}
        />
      </div>
    </div>
  );
};

export default Step4;
