import { useState } from "react";
import "../style/toggle.scss";

const Toggle = ({ label, toggled = false, onClick }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    if (onClick) onClick(!isToggled);
  };

  return (
    <div className="toogleBtn">
      <div className="label">{label}</div>
      <input type="checkbox" defaultChecked={isToggled} hidden />
      <span className={isToggled ? 'toogle-display checked' : 'toogle-display'} onClick={callback} />
    </div>
  );
};

export default Toggle;
