import React, { useEffect, useState } from "react";
import "../style/auth.scss";
import * as formik from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import MicrosoftAuthComponent from "../component/Auth/MicrosoftAuthComponent";

const SignUp = () => {
  const { Formik } = formik;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate]);

  const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmitData = (data, { resetForm }) => {
    const { email, password } = data;

    if (email === "key" && password === "key") {
      resetForm();
      localStorage.setItem("isAuthenticated", "true");
      navigate("/");
    } else {
      setErrorMessage("Invalid email or password");
    }
  };

  return (
    <section className="main-auth">
      <div className="main-auth-form">
        <h3 className="py-2">Sign Up</h3>
        <p className="mb-0 py-2">
          Already have an account?
          <NavLink to="/sign-in">&nbsp; Sign In</NavLink>
        </p>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmitData}
          initialValues={{
            email: "",
            password: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="mt-4  " style={{'display':'flex', 'flex-wrap':'wrap'}}>
              <Form.Group controlId="email" className="w-100 p-2">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>


              </Form.Group>

              <Form.Group controlId="fName" className="w-50 p-2">
                <Form.Control
                  type="fName"
                  placeholder="First Name"
                  name="fName"
                  value={values.fName}
                  onChange={handleChange}
                  isInvalid={!!errors.fName}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.fName}
                </Form.Control.Feedback>


              </Form.Group>

              <Form.Group controlId="lName" className="w-50 p-2">
                <Form.Control
                  type="lName"
                  placeholder="Last Name"
                  name="lName"
                  value={values.lName}
                  onChange={handleChange}
                  isInvalid={!!errors.lName}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.lName}
                </Form.Control.Feedback>


              </Form.Group>



              <Form.Group controlId="password" className="p-2 w-100">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              {errorMessage && (
                <div className="text-danger text-center mt-3">
                  {errorMessage}
                </div>
              )}
              

              <Button
                type="submit"
                variant="primary"
                className="w-100 mt-4 auth-button"
              >
                Sign Up
              </Button>
              <div className="or">Or</div>

              <MicrosoftAuthComponent />

              <hr/>
             
              <div className="message flex"> 
                <input type="checkbox"></input>
                <div> By clicking Create account, I agree tha I have read all accepted the Terms of Use and Privacy Policy.
                </div>
                </div>
              
              <div className="message">
                Protected by reCAPTCHA and subjected to Rhombus <NavLink>Privacy Policy </NavLink> and <NavLink>Terms of Service.</NavLink>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default SignUp;
