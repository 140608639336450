import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../../constants/api.const';
import { NAME_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, SUPER_USER } from '../../constants/key.const';

const MicrosoftLogin = () => {
  const location = useLocation();
  const [access_token, setAccessKey] = useState(null);
  const [refresh_token, setRefreshKey] = useState(null);
  const [ms_user_id, setMsuserId] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('access_token');
    const refresh_token = searchParams.get('refresh_token');
    const user_id = searchParams.get('microsoft_id');

    if (token) {
        setAccessKey(token);
    };
    if (refresh_token) {
      setRefreshKey(refresh_token)
    };
    if (user_id) {
      setMsuserId(user_id)
    };
  }, [location.search]);

  useEffect(() => {
    const handleMicrosoftSubmit = async () => {
      try {
          const response = await axios.post(API.MICROSOFT_LOGIN, {
              access_token: access_token, refresh_token:refresh_token,
              ms_user_id: ms_user_id
          });
          console.log("response ----------", response);
          if (response.data.message) {
              sessionStorage.setItem(TOKEN_KEY, response.data.access);
              sessionStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshtoken);
              const name = response?.data?.data?.first_name + " "+ response?.data?.data?.last_name;
              sessionStorage.setItem(NAME_KEY, name);
              sessionStorage.setItem(SUPER_USER, response?.data?.data?.is_superuser);
              window.location.href = '/home';

              setTimeout(() => {
                  toast.success('Login Successfully!', {
                      className: 'top-16',
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      theme: "light",
                      style: {
                          backgroundColor: 'green',
                          color: 'white'
                      },
                  });
              }, 2000);
          }
      } catch (error) {
          console.log("error response====", error);
          toast.error('Login Failed!', {
              className: 'top-16',
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "light",
              style: {
                  backgroundColor: 'red',
                  color: 'white'
              },
          });
      }
    };

    if (access_token) {
      handleMicrosoftSubmit();
    }
  }, [access_token]); // Include access_token in the dependency array

  return (
    <div>
     Loading ........
    </div>
  );
};

export default MicrosoftLogin;
