import React from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ScheduleIcon from '@mui/icons-material/Schedule';

const StatusDisplay = ({isReviewed}) => {
  return (
    <>
       {isReviewed==false && (<ScheduleIcon style={{color:"#FF4B00", fontSize:'28px', stroke : '#FF4B00', strokeWidth:'1px'}}/>)} 
       {isReviewed==true && (<TaskAltIcon style={{color:"#002790", fontSize:'28px', stroke : '#002790', strokeWidth:'1px'}}/>)} 
       </>
  )
}

export default StatusDisplay