import React, { useState } from "react";
import "../style/RecordingDetails.scss";
import { L } from "../langauge/english.lang";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../services/dateService";
import { API, AUTH_HEADER } from "../constants/api.const";
import { toast } from "react-toastify";

const RecordingDetails = () => {
  const location = useLocation();

  const { recording, isAdmin } = location.state;
  console.log(recording)
  const [formData, setFormData] = useState({
    description: recording?.consultation_summary?.length>0?recording.consultation_summary[0].summary_text:'',
    GGZNHNCode: recording?.consultation_summary?.length>0?recording.consultation_summary[0].code?.split(',')[0]:'',
    ZPMCode: recording?.consultation_summary?.length>0?recording.consultation_summary[0].code?.split(',')[1]:'',
  });

  const handleChange = (e) => {
    if(recording.is_reviewed) return
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleKeyUp = (e) => {
    if (e.code == "Enter") {
      const payload = {
        consultation_summaries:[
            {
                ai_model_name:"chatgpt_4",
                summary_text:formData.description,
                codes : `${formData.GGZNHNCode}, ${formData.ZPMCode}`
            }
        ]
    }

    axios.patch(API.UPDATE_RECORDING + recording.id + "/", payload, AUTH_HEADER).then(d => {
       toast.success(L.MSG.CONVERSATION_SAVED)
      }).catch(e => {
    })
    }
  };

  return (
    <div className="recording-details" onKeyUp={handleKeyUp}>
      <div className="detail-container">
        <div className="header-recording">
          <div className="name-box">
            <div className="label"> {L.CLIENT} </div>
            <div className="value">
              {" "}
              {recording?.appointment?.patient?.first_name}{" "}
              {recording?.appointment?.patient?.last_name}{" "}
            </div>
          </div>
          <div className="date-box">
            <div className="date">
              {formatDate(recording?.appointment?.appointment_time, {
                dateStyle: "medium",
              })}
            </div>
            <div className="time">
              {formatDate(recording?.appointment?.appointment_time, {
                timeStyle: "short",
              })}
            </div>
          </div>
        </div>

        <div className="content">
          <div className="header-recording"> {L.NOTE} </div>
          <textarea
            name="description"
            className="text-box"
            value={formData.description}
            onChange={handleChange}
            readOnly = {recording.is_reviewed}
          ></textarea>
        </div>

       {isAdmin && ( <div className="footer">
          <div className="box">
            <div className="label">GGZ-NHN Code</div>
            <input
              type="text"
              name="GGZNHNCode"
              className="text"
              value={formData.GGZNHNCode}
              onChange={handleChange}
            />
          </div>
          <div className="box">
            <div className="label">ZPM Code</div>
            <input
              type="text"
              name="ZPMCode"
              className="text"
              value={formData.ZPMCode}
              onChange={handleChange}
            />
          </div>
        </div>)}
      </div>
    </div>
  );
};

export default RecordingDetails;
