import React from "react";

const ConfirmationModal = ({
  title,
  text,
  confirmButtonText = "Yes",
  cancelButtonText = "Cancel",
  show = false,
  handleClose,
}) => {
  return (
    <>
    
    {show &&   <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex : 98
          
        }}
      >
        <div
          style={{
            boxShadow: "0px 0px 12px 3px #00000077",
            borderRadius : '12px',
            padding: '30px 12px',
            width : '330px',
            maxWidth :'95%',
            display : 'flex',
            flexDirection : 'column',
            gap : '12px',
            alignItems : 'center',
            justifyContent : 'center',
            position : 'relative',
            zIndex : 100,
          backgroundColor : 'white'
          }}
        >
            <div style={{fontSize : '22px', fontWeight : 'bold'}}> {title}</div>
            <div style={{textAlign : 'center', fontSize : '14px', color : '#191A23ee'}}> {text}  </div>

            <div style={{display : 'flex', gap : '15px', alignItems : 'center'}}>
                <button style={{
                    minWidth : "110px",
                    boxSizing : 'border-box',
                    height : '35px',
                    border : 'none',
                    cursor : 'pointer',
                    borderRadius : '8px',
                    background : '#3667E9',
                    color : 'white'
                }} onClick={e=>handleClose(true)}> {confirmButtonText} </button>
                <button style={{
                    minWidth : "110px",
                    boxSizing : 'border-box',
                    height : '35px',
                    border : 'none',
                    cursor : 'pointer',
                    borderRadius : '8px',
                    background : '#CBCBCB',
                    color : '#000'
                }} onClick={e=>handleClose(false)}> {cancelButtonText} </button>
            </div>
        </div>
        <div
      onClick={e=>handleClose(false)}
        style={{
            position : 'fixed',
          width: "100vw",
          height: "100vh",
          top: "0",
          left: "0",
          backgroundColor:'#000000aa',
          backdropFilter : "blur('1px')",
          zIndex : 99
        }}
      ></div>
      </div>}

    
    </>
  );
};

export default ConfirmationModal;
