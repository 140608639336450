import React, { useEffect, useState } from "react";
import "../style/auth.scss";
import * as formik from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import MicrosoftAuthComponent from "../component/Auth/MicrosoftAuthComponent";
import { L } from "../langauge/english.lang";

const MicrosoftSignIn = () => {
  const { Formik } = formik;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [navigate]);

  const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmitData = (data, { resetForm }) => {
    const { email, password } = data;

    if (email === "key" && password === "key") {
      resetForm();
      localStorage.setItem("isAuthenticated", "true");
      navigate("/");
    } else {
      setErrorMessage(L.MSG.INVALID_EMAIL_PASSWORD);
    }
  };

  return (
    <section className="main-auth ">
      <div className="main-auth-form" >
        <h3> {L.SIGN_IN} </h3>
        <p className="mb-4">
          {L.CONTINUE_WT_MS}
        </p>
        <MicrosoftAuthComponent />

      </div>
    </section>
  );
};

export default MicrosoftSignIn;
