// import React, { useState, useEffect } from "react";
// import "../style/VoiceRecorder.scss";
// import { toast, ToastContainer } from "react-toastify";
// import MicIcon from "@mui/icons-material/Mic";
// import { L } from "../langauge/english.lang";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import { useRef } from "react";
// import ConfirmationModal from "./ConfirmationModal";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import { USER_ID_KEY } from "../constants/key.const";

// const VoiceRecorder = ({ setActiveStep, setPage }) => {
//   setPage(L.RECORD);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [audioUrl, setAudioUrl] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [recordingInterval, setRecordingInterval] = useState(null);
//   const [deleteModal, openDeleteModal] = useState(false);
//   const [recordingData, setRecordingData] = useState({});

//   // replay data 
//   const [audio, setAudio] = useState(false)
//   const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
//   const audioRef = useRef(null)
//   const [playerInterval, setPlayerInterval] = useState(null)

//   const navigate = useNavigate();

//   const { state } = useLocation();

//   const [isLoading, setIsLoading] = useState(false);

//   const toggleAudioPlay = (e)=>{
//     if(e==true && audioRef?.current?.paused) {
//       audioRef?.current?.play()
//       const interval = setInterval(() => {
//         const val = audioRef.current?.currentTime/recordingDuration
//         if(recordingPlayerWidth) setRecordingPlayerWidth((wt)=>`${val*100000}%`)
//         if(audioRef?.current?.ended ) setAudio(false)
//       }, 1000);
//     setPlayerInterval(interval)
//     }
//     else if(e==false && !!!audioRef?.current?.paused){
//       audioRef?.current?.pause()
//       // clearInterval(playerRef)
//     }
//     setAudio(e)
//   }

//   // console.log(audioRef.current?.currentTime, audioRef.current?.duration, audioRef.current?.paused)
//   useEffect(() => {
//     return () => {
//       if (recordingInterval) clearInterval(recordingInterval);
//     };
//   }, [recordingInterval]);

//   useEffect(() => {
//     return () => {
//       if (playerInterval) clearInterval(playerInterval);
//     };
//   }, [playerInterval]);

  

//   const updateValues = (key, value) => {
//     setRecordingData(data=>({
//       ...data,
//       [key]: value,
//     }));
//   };

//   const startRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const recorder = new MediaRecorder(stream);
//         setMediaRecorder(recorder);

//         recorder.start();
//         setIsRecording(true);
//         setIsPaused(false);
//         setRecordingDuration(0); // Reset duration
//         setAudioChunks([]);

//         const interval = setInterval(() => {
//           setRecordingDuration((prev) => prev + 1000);
//         }, 1000);
//         setRecordingInterval(interval);

//         // recorder.ondataavailable = (event) => {
//         //   const chunks = [...audioChunks, event.data];
//         //   setAudioChunks(chunks);
//         //   const audioBlob = new Blob(chunks, { type: "audio/mpeg" });

//         //     console.log(audioBlob.size, "on data validate")
//         //   setAudioUrl(URL.createObjectURL(audioBlob));
//         // };

//         recorder.ondataavailable = (event) => {
//           // console.log("on stop called");
//           // const audioBlob = new Blob(audioChunks, { type: "audio/mpeg" });
//           // console.log(audioBlob.size, "on stop")
//           // if (!audioBlob || audioBlob.length < 0)
//           // setAudioUrl(URL.createObjectURL(audioBlob));
//           // clearInterval(interval);
//           // setRecordingInterval(null);
//           // Static data for testing
//           const chunks = [...audioChunks, event?.data];
//           setAudioChunks(chunks);
//           const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
//           console.log(audioBlob.size, "on data validate");
//           setAudioUrl(URL.createObjectURL(audioBlob));
//           clearInterval(interval);
//           setRecordingInterval(null);
//           let transcription =
//             "This is a static transcription for testing purposes.";
//           let gpt4oSummary = "This is a Static GPT-4o summary";
//           let gpt4oCodes = "naam: Static GPT-4o<br>prestatiecode: 123456";
//           let llama3Summary = "This is Static Llama3 summary";
//           let llama3Codes = "naam: Static Llama3<br>prestatiecode: 654321";

//           setTimeout(async () => {
//             try {
//               const transcribeFD = new FormData();
//               transcribeFD.append(
//                 "file",
//                 new File([audioBlob], "recording.wav", { type: "audio/wav" })
//               );
//               const dt = new Date(recordingDuration);
//               console.log(dt);
//               const backendFD = new FormData();
//               backendFD.append(
//                 "recording_file",
//                 new File([audioBlob], "recording.wav", { type: "audio/wav" })
//               );
//               backendFD.append("appointment", state.apt?.id);
//               backendFD.append(
//                 "recording_duration",
//                 Math.floor(audioBlob.size / (1024 * 16))
//               );

//               setIsLoading(true);

//               toast.success(L.MSG.CONVERSATION_PROCESSED);
//               setTimeout(() => {
//                 toast.info(L.MSG.THANKS_FOR_PATIENCE);
//               }, 10000);

//               axios
//                 .post(API.POST_RECORDING, backendFD, AUTH_HEADER)
//                 .then((d) => {
//                   updateValues("recordingId" , d?.data?.data?.id)
//                   toast.success(L.MSG.CONVERSATION_SAVED);
//                 })
//                 .catch((e) => {
//                   // console.log(e)
//                   toast.error(
//                     e.response?.data?.error ||
//                       "Error occured while saving the conversation."
//                   );
//                 });

//               let response;
//               try {
//                 response = await fetch(
//                   "https://aisummarizer.cloud/transcribe/audio-text/",
//                   {
//                     method: "POST",
//                     body: transcribeFD,
//                     headers: {
//                       accept: "application/json",
//                     },
//                   }
//                 );
//               } catch (e) {
//                 console.log(e);
//               }

//               if (response.ok) {
//                 const data = await response.json();
//                 console.log("Transcription:", data);
//                 transcription = data.transcription;
//                 updateValues("transcription", data.transcription);
//                 console.log(transcription,"trans")
//                 const wordCount = transcription?.split(" ")?.length || 0;
//                 if (wordCount > 0) {
//                   const processGPT4o = fetch(
//                     "https://aisummarizer.cloud/transcript/process_transcript",
//                     {
//                       method: "POST",
//                       headers: {
//                         "Content-Type": "application/json",
//                         accept: "application/json",
//                       },
//                       body: JSON.stringify({
//                         transcript: transcription,
//                         model: "chatgpt_4",
//                         //duration: Math.floor(audioBlob.size / (1024 * 16))
//                       }),
//                     }
//                   );

//                   // const processLlama3 = fetch(
//                   //   "https://aisummarizer.cloud/flask_backend/process_transcript",
//                   //   {
//                   //     method: "POST",
//                   //     headers: {
//                   //       "Content-Type": "application/json",
//                   //       accept: "application/json",
//                   //     },
//                   //     body: JSON.stringify({
//                   //       transcript: transcription,
//                   //       model: "llama3",
//                   //       duration: data.duration,
//                   //       medical_gender: "profileData.gender",
//                   //       medical_patient: " clientData.gender ",
//                   //     }),
//                   //   }
//                   // );

//                   const [
//                     gpt4oResponse,
//                     // , llama3Response
//                   ] = await Promise.all([
//                     processGPT4o,
//                     // processLlama3,
//                   ]);

//                   if (
//                     gpt4oResponse.ok
//                     // && llama3Response.ok
//                   ) {
//                     const gpt4oData = await gpt4oResponse.json();
//                     // const llama3Data = await llama3Response.json();

//                     gpt4oSummary = gpt4oData.summary;
//                     gpt4oCodes = JSON.stringify(gpt4oData.codes);
//                     // llama3Summary = llama3Data.summary;
//                     // llama3Codes = `naam: ${llama3Data.naam}<br>prestatiecode: ${llama3Data.prestatiecode}`;

//                     const payload = {
//                       recording_duration:  Math.floor(audioBlob.size / (1024 * 16)),
//                       consultation_summaries: [
//                         {
//                           ai_model_name: "chatgpt_4",
//                           summary_text: gpt4oSummary,
//                           code: gpt4oCodes,
//                         },
//                         // {
//                         //   ai_model_name: "llama3",
//                         //   summary_text: llama3Summary,
//                         //   code: llama3Codes,
//                         // },
//                       ],
//                       transcript: transcription,
//                       is_recording_paused: false,
//                       is_recording_deleted: false,
//                     };
//                     updateValues("modelData", payload);

//                     const id = parseInt(
//                       sessionStorage.getItem(USER_ID_KEY) || "1"
//                     );

//                     axios
//                       .patch(
//                         API.UPDATE_RECORDING + id + "/",
//                         payload,
//                         AUTH_HEADER
//                       )
//                       .then((d) => {})
//                       .catch((e) => {
//                         toast.error(
//                           e.response?.data?.error ||
//                             "Error while updating the recording details"
//                         );
//                       });

//                     updateValues("gpt4oSummary", gpt4oSummary);
//                     updateValues("gpt4oCodes", gpt4oCodes);
//                   } else {
//                     // console.error("Failed to process transcripts");
//                     updateValues("gpt4oSummary", "");
//                     updateValues("gpt4oCodes", "");
//                   }
//                 } else {
//                   console.log("Transcription too short, skipping processing.");
//                   updateValues("gpt4oSummary", "");
//                   updateValues("gpt4oCodes", "");
//                 }
//               } else {
//                 console.log("Failed to transcribe audio");
//               }

//               // Using static data
//               updateValues("gpt4oSummary", gpt4oSummary);
//               updateValues("gpt4oCodes", gpt4oCodes);
//             } catch (error) {
//               console.log("Error:", error);
//             } finally {
//               setIsLoading(false);
//               // storeData(clientData.clientNumber, transcription, { gpt4o: { summary: gpt4oSummary, codes: gpt4oCodes }, llama3: { summary: llama3Summary, codes: llama3Codes } });
//             }
//           }, 1000);
//         };
//       })
//       .catch((error) => {
//         console.log("Error accessing microphone:", error);
//       });
//   };

//   const pauseRecording = () => {
//     console.log("on pause callled");
//     if (mediaRecorder && mediaRecorder.state === "recording") {
//       mediaRecorder.pause();
//       setIsPaused(true);
//       clearInterval(recordingInterval); // Pause the timer
//     }
//   };

//   const resumeRecording = () => {
//     console.log("On resume called");
//     if (mediaRecorder && mediaRecorder.state === "paused") {
//       mediaRecorder.resume();
//       setIsPaused(false);

//       const interval = setInterval(() => {
//         setRecordingDuration((prev) => prev + 1000);
//       }, 1000);
//       setRecordingInterval(interval);
//     }
//   };

//   const triggerStopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);
//       setIsPaused(false);
//       clearInterval(recordingInterval); // Stop the timer
//       // const audioBlob = new Blob(audioChunks, { type: "audio/mpeg" });
//       // setAudioUrl(URL.createObjectURL(audioBlob));
//     }
//   };

//   const deleteRecording = (e) => {
//     openDeleteModal(false);
//     if (e == false) return;
//     const recordingId = recordingData?.recordingId;
//     setActiveStep(1);
//     setMediaRecorder(null);
//     setAudioChunks([]);
//     setAudioUrl(null);
//     setIsRecording(false);
//     setIsPaused(false);
//     setRecordingDuration(0);
//     setRecordingInterval(null);
//     setRecordingData({});
//     if (!recordingId) return;
//     axios.patch(API.UPDATE_RECORDING+recordingId+"/", {is_recording_deleted : true}, AUTH_HEADER).then((res)=>{
//       if(res.status==200) toast.success(L.MSG.RECORDING_DELETED);

//     }).catch(e=>{
//       console.log(e)
//     })
//   };

//   const moveNext = () => {
//     setActiveStep(2);
//     console.log(recordingData)
//     navigate("/home/walkthrough/review", { state: { ...state, recordingId : recordingData?.recordingId } });
//   };

//   return (
//     <>
//       <ToastContainer />
//       <ConfirmationModal
//         title={L.DELETE_MODAL.TITLE}
//         text={L.DELETE_MODAL.TEXT}
//         confirmButtonText={L.DELETE_MODAL.YES}
//         cancelButtonText={L.DELETE_MODAL.NO}
//         show={deleteModal || false}
//         handleClose={deleteRecording}
//       />
//       <div className="voice-recorder">
//         <div className="head">
//           {!isRecording && !audioUrl && L.START_RECORDING}
//           {isRecording && !isPaused && L.RECORDING + "..."}
//           {isPaused && L.RESUME_RECORDING}
//           {audioUrl && L.COMPLETED_RECORDING}
//         </div>

//         <div className="controller">
//           {!isRecording && !audioUrl && (
//             <MicIcon onClick={startRecording} className="icon" />
//           )}
//           {isRecording && !isPaused && (
//             <PauseIcon onClick={pauseRecording} className="icon recording" />
//           )}
//           {isPaused && (
//             <PlayArrowIcon onClick={resumeRecording} className="icon" />
//           )}

//           {(isRecording && !!!audioUrl) && (
//             <div className="time">
//               {new Date(recordingDuration).toISOString().substr(11, 8)}
//             </div>
//           )}

//           {audioUrl && (
//             <>
//               <div className="audio-display">
//                 <audio
//                   src={audioUrl}
//                   ref={audioRef}
//                   controls
//                   className="audio-element"
//                   hidden
//                 />
//                {audioRef.current && (<>
//                 {!audio && (<PlayArrowIcon onClick={e=>toggleAudioPlay(true)} className="icon-mini"/>) }
//                 {audio && (<PauseIcon onClick={e=>toggleAudioPlay(false)} className="icon-mini"/>) }
//                 <div className="slider">
//                   <div className="pad" style={{
//                     width : recordingPlayerWidth
//                   }}></div>
//                 </div>
//                 <div className="timer">
//                 {new Date(recordingDuration).toISOString().substr(11, 8)}
//                 </div></>)}

//               </div>
//             </>
//           )}
//         </div>

//         <div className="footer">
//           {(isRecording || audioUrl) && (
//             <>
//               <DeleteIcon
//                 className="btn"
//                 onClick={(e) => openDeleteModal(true)}
//               />
//               {!audioUrl && (
//                 <SaveIcon onClick={triggerStopRecording} className="btn" />
//               )}
//               {audioUrl && (
//                 <ArrowRightAltIcon className="btn" onClick={moveNext} />
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VoiceRecorder;















// import React, { useState, useEffect, useRef } from "react";
// import "../style/VoiceRecorder.scss";
// import { toast, ToastContainer } from "react-toastify";
// import MicIcon from "@mui/icons-material/Mic";
// import { L } from "../langauge/english.lang";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ConfirmationModal from "./ConfirmationModal";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import { USER_ID_KEY } from "../constants/key.const";
// import CircularProgress from "@mui/material/CircularProgress";

// const VoiceRecorder = ({ setActiveStep, setPage }) => {
//   setPage(L.RECORD);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [audioUrl, setAudioUrl] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [recordingInterval, setRecordingInterval] = useState(null);
//   const [deleteModal, openDeleteModal] = useState(false);
//   const [recordingData, setRecordingData] = useState({});

//   const [audio, setAudio] = useState(false);
//   const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
//   const audioRef = useRef(null);
//   const [playerInterval, setPlayerInterval] = useState(null);

//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [isLoading, setIsLoading] = useState(false);
//   const [processingStep, setProcessingStep] = useState("");

//   const toggleAudioPlay = (e) => {
//     if (e == true && audioRef?.current?.paused) {
//       audioRef?.current?.play();
//       const interval = setInterval(() => {
//         const val = audioRef.current?.currentTime / recordingDuration;
//         if (recordingPlayerWidth) setRecordingPlayerWidth((wt) => `${val * 100000}%`);
//         if (audioRef?.current?.ended) setAudio(false);
//       }, 1000);
//       setPlayerInterval(interval);
//     } else if (e == false && !!!audioRef?.current?.paused) {
//       audioRef?.current?.pause();
//     }
//     setAudio(e);
//   };

//   useEffect(() => {
//     return () => {
//       if (recordingInterval) clearInterval(recordingInterval);
//       if (playerInterval) clearInterval(playerInterval);
//     };
//   }, [recordingInterval, playerInterval]);

//   const updateValues = (key, value) => {
//     setRecordingData(data => ({
//       ...data,
//       [key]: value,
//     }));
//   };

//   const startRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const recorder = new MediaRecorder(stream);
//         setMediaRecorder(recorder);

//         recorder.start();
//         setIsRecording(true);
//         setIsPaused(false);
//         setRecordingDuration(0);
//         setAudioChunks([]);

//         const interval = setInterval(() => {
//           setRecordingDuration((prev) => prev + 1000);
//         }, 1000);
//         setRecordingInterval(interval);

//         recorder.ondataavailable = (event) => {
//           const chunks = [...audioChunks, event?.data];
//           setAudioChunks(chunks);
//           const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
//           setAudioUrl(URL.createObjectURL(audioBlob));
//           clearInterval(interval);
//           setRecordingInterval(null);

//           processRecording(audioBlob);
//         };
//       })
//       .catch((error) => {
//         console.error("Error accessing microphone:", error);
//         toast.error("Failed to access microphone. Please check your permissions.");
//       });
//   };

//   const processRecording = async (audioBlob) => {
//     setIsLoading(true);
//     setProcessingStep("Initiating processing...");

//     try {
//       const transcribeFD = new FormData();
//       transcribeFD.append(
//         "file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       const backendFD = new FormData();
//       backendFD.append(
//         "recording_file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       backendFD.append("appointment", state.apt?.id);
//       backendFD.append(
//         "recording_duration",
//         Math.floor(audioBlob.size / (1024 * 16))
//       );

//       setProcessingStep("Saving recording...");
//       const recordingResponse = await axios.post(API.POST_RECORDING, backendFD, AUTH_HEADER);
//       updateValues("recordingId", recordingResponse?.data?.data?.id);
//       toast.success(L.MSG.CONVERSATION_SAVED);

//       setProcessingStep("Transcribing audio...");
//       const transcribeResponse = await fetch(
//         "https://aisummarizer.cloud/transcribe/audio-text/",
//         {
//           method: "POST",
//           body: transcribeFD,
//           headers: {
//             accept: "application/json",
//           },
//         }
//       );

//       if (transcribeResponse.ok) {
//         const data = await transcribeResponse.json();
//         const transcription = data.transcription;
//         updateValues("transcription", transcription);

//         setProcessingStep("Analyzing with Llama3...");
//         const processLlama3 = await fetch(
//           "https://aisummarizer.cloud/transcript/process_transcript",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               accept: "application/json",
//             },
//             body: JSON.stringify({
//               transcript: transcription,
//               model: "llama3",
//             }),
//           }
//         );

//         if (processLlama3.ok) {
//           const llama3Data = await processLlama3.json();
//           const llama3Summary = llama3Data.summary;
//           const llama3Codes = JSON.stringify(llama3Data.codes);

//           const payload = {
//             recording_duration: Math.floor(audioBlob.size / (1024 * 16)),
//             consultation_summaries: [
//               {
//                 ai_model_name: "llama3",
//                 summary_text: llama3Summary,
//                 code: llama3Codes,
//               },
//             ],
//             transcript: transcription,
//             is_recording_paused: false,
//             is_recording_deleted: false,
//           };
//           updateValues("modelData", payload);

//           const id = parseInt(sessionStorage.getItem(USER_ID_KEY) || "1");

//           setProcessingStep("Updating recording details...");
//           await axios.patch(API.UPDATE_RECORDING + id + "/", payload, AUTH_HEADER);

//           updateValues("llama3Summary", llama3Summary);
//           updateValues("llama3Codes", llama3Codes);

//           toast.success("Processing completed successfully!");
//         } else {
//           throw new Error("Failed to process transcripts");
//         }
//       } else {
//         throw new Error("Failed to transcribe audio");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`An error occurred: ${error.message}`);
//     } finally {
//       setIsLoading(false);
//       setProcessingStep("");
//     }
//   };

//   const pauseRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "recording") {
//       mediaRecorder.pause();
//       setIsPaused(true);
//       clearInterval(recordingInterval);
//     }
//   };

//   const resumeRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "paused") {
//       mediaRecorder.resume();
//       setIsPaused(false);

//       const interval = setInterval(() => {
//         setRecordingDuration((prev) => prev + 1000);
//       }, 1000);
//       setRecordingInterval(interval);
//     }
//   };

//   const triggerStopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);
//       setIsPaused(false);
//       clearInterval(recordingInterval);
//     }
//   };

//   const deleteRecording = (e) => {
//     openDeleteModal(false);
//     if (e == false) return;
//     const recordingId = recordingData?.recordingId;
//     setActiveStep(1);
//     setMediaRecorder(null);
//     setAudioChunks([]);
//     setAudioUrl(null);
//     setIsRecording(false);
//     setIsPaused(false);
//     setRecordingDuration(0);
//     setRecordingInterval(null);
//     setRecordingData({});
//     if (!recordingId) return;
//     axios.patch(API.UPDATE_RECORDING + recordingId + "/", { is_recording_deleted: true }, AUTH_HEADER)
//       .then((res) => {
//         if (res.status == 200) toast.success(L.MSG.RECORDING_DELETED);
//       })
//       .catch(e => {
//         console.error(e);
//         toast.error("Failed to delete recording.");
//       });
//   };

//   const moveNext = () => {
//     setActiveStep(2);
//     navigate("/home/walkthrough/review", { state: { ...state, recordingId: recordingData?.recordingId } });
//   };

//   return (
//     <>
//       <ToastContainer />
//       <ConfirmationModal
//         title={L.DELETE_MODAL.TITLE}
//         text={L.DELETE_MODAL.TEXT}
//         confirmButtonText={L.DELETE_MODAL.YES}
//         cancelButtonText={L.DELETE_MODAL.NO}
//         show={deleteModal || false}
//         handleClose={deleteRecording}
//       />
//       <div className="voice-recorder">
//         <div className="head">
//           {!isRecording && !audioUrl && L.START_RECORDING}
//           {isRecording && !isPaused && L.RECORDING + "..."}
//           {isPaused && L.RESUME_RECORDING}
//           {audioUrl && !isLoading && L.COMPLETED_RECORDING}
//           {isLoading && "Processing..."}
//         </div>

//         <div className="controller">
//           {!isRecording && !audioUrl && !isLoading && (
//             <MicIcon onClick={startRecording} className="icon" />
//           )}
//           {isRecording && !isPaused && !isLoading && (
//             <PauseIcon onClick={pauseRecording} className="icon recording" />
//           )}
//           {isPaused && !isLoading && (
//             <PlayArrowIcon onClick={resumeRecording} className="icon" />
//           )}

//           {(isRecording && !!!audioUrl) && (
//             <div className="time">
//               {new Date(recordingDuration).toISOString().substr(11, 8)}
//             </div>
//           )}

//           {audioUrl && !isLoading && (
//             <>
//               <div className="audio-display">
//                 <audio
//                   src={audioUrl}
//                   ref={audioRef}
//                   controls
//                   className="audio-element"
//                   hidden
//                 />
//                 {audioRef.current && (
//                   <>
//                     {!audio && (<PlayArrowIcon onClick={e => toggleAudioPlay(true)} className="icon-mini" />)}
//                     {audio && (<PauseIcon onClick={e => toggleAudioPlay(false)} className="icon-mini" />)}
//                     <div className="slider">
//                       <div className="pad" style={{
//                         width: recordingPlayerWidth
//                       }}></div>
//                     </div>
//                     <div className="timer">
//                       {new Date(recordingDuration).toISOString().substr(11, 8)}
//                     </div>
//                   </>
//                 )}
//               </div>
//             </>
//           )}

//           {isLoading && (
//             <div className="loading-container">
//               <CircularProgress />
//               <p>{processingStep}</p>
//             </div>
//           )}
//         </div>

//         <div className="footer">
//           {(isRecording || audioUrl) && !isLoading && (
//             <>
//               <DeleteIcon
//                 className="btn"
//                 onClick={(e) => openDeleteModal(true)}
//               />
//               {!audioUrl && (
//                 <SaveIcon onClick={triggerStopRecording} className="btn" />
//               )}
//               {audioUrl && (
//                 <ArrowRightAltIcon className="btn" onClick={moveNext} />
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VoiceRecorder;









// import React, { useState, useEffect, useRef } from "react";
// import "../style/VoiceRecorder.scss";
// import { toast, ToastContainer } from "react-toastify";
// import MicIcon from "@mui/icons-material/Mic";
// import { L } from "../langauge/english.lang";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ConfirmationModal from "./ConfirmationModal";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import CircularProgress from "@mui/material/CircularProgress";

// const VoiceRecorder = ({ setActiveStep, setPage }) => {
//   setPage(L.RECORD);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [audioUrl, setAudioUrl] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [recordingInterval, setRecordingInterval] = useState(null);
//   const [deleteModal, openDeleteModal] = useState(false);
//   const [recordingData, setRecordingData] = useState({});

//   const [audio, setAudio] = useState(false);
//   const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
//   const audioRef = useRef(null);
//   const [playerInterval, setPlayerInterval] = useState(null);

//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [isLoading, setIsLoading] = useState(false);
//   const [processingStep, setProcessingStep] = useState("");

//   const toggleAudioPlay = (e) => {
//     if (e === true && audioRef?.current?.paused) {
//       audioRef?.current?.play();
//       const interval = setInterval(() => {
//         const val = audioRef.current?.currentTime / recordingDuration;
//         if (recordingPlayerWidth) setRecordingPlayerWidth(`${val * 100}%`);
//         if (audioRef?.current?.ended) setAudio(false);
//       }, 1000);
//       setPlayerInterval(interval);
//     } else if (e === false && !audioRef?.current?.paused) {
//       audioRef?.current?.pause();
//     }
//     setAudio(e);
//   };

//   useEffect(() => {
//     return () => {
//       if (recordingInterval) clearInterval(recordingInterval);
//       if (playerInterval) clearInterval(playerInterval);
//     };
//   }, [recordingInterval, playerInterval]);

//   const updateValues = (key, value) => {
//     setRecordingData(data => ({
//       ...data,
//       [key]: value,
//     }));
//   };

//   const startRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const recorder = new MediaRecorder(stream);
//         setMediaRecorder(recorder);

//         recorder.start();
//         setIsRecording(true);
//         setIsPaused(false);
//         setRecordingDuration(0);
//         setAudioChunks([]);

//         const interval = setInterval(() => {
//           setRecordingDuration((prev) => prev + 1000);
//         }, 1000);
//         setRecordingInterval(interval);

//         recorder.ondataavailable = (event) => {
//           const chunks = [...audioChunks, event?.data];
//           setAudioChunks(chunks);
//           const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
//           setAudioUrl(URL.createObjectURL(audioBlob));
//           clearInterval(interval);
//           setRecordingInterval(null);

//           processRecording(audioBlob);
//         };
//       })
//       .catch((error) => {
//         console.error("Error accessing microphone:", error);
//         toast.error("Failed to access microphone. Please check your permissions.");
//       });
//   };

//   const processRecording = async (audioBlob) => {
//     setIsLoading(true);
//     setProcessingStep("Initiating processing...");

//     try {
//       const transcribeFD = new FormData();
//       transcribeFD.append(
//         "file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       const backendFD = new FormData();
//       backendFD.append(
//         "recording_file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       backendFD.append("appointment", state.apt?.id);
//       backendFD.append(
//         "recording_duration",
//         Math.floor(audioBlob.size / (1024 * 16))
//       );

//       setProcessingStep("Saving recording...");
//       const recordingResponse = await axios.post(API.POST_RECORDING, backendFD, AUTH_HEADER);
//       const recordingId = recordingResponse?.data?.data?.id;
//       updateValues("recordingId", recordingId);
//       toast.success(L.MSG.CONVERSATION_SAVED);

//       setProcessingStep("Transcribing audio...");
//       const transcribeResponse = await fetch(
//         "https://aisummarizer.cloud/transcribe/audio-text/",
//         {
//           method: "POST",
//           body: transcribeFD,
//           headers: {
//             accept: "application/json",
//           },
//         }
//       );

//       if (transcribeResponse.ok) {
//         const data = await transcribeResponse.json();
//         const transcription = data.transcription;
//         updateValues("transcription", transcription);

//         setProcessingStep("Analyzing with Llama3...");
//         const processLlama3 = await fetch(
//           "https://aisummarizer.cloud/transcript/process_transcript",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               accept: "application/json",
//             },
//             body: JSON.stringify({
//               transcript: transcription,
//               model: "llama3",
//             }),
//           }
//         );

//         if (processLlama3.ok) {
//           const llama3Data = await processLlama3.json();
//           const llama3Summary = llama3Data.summary;
//           const llama3Codes = JSON.stringify(llama3Data.codes);

//           const payload = {
//             recording_duration: Math.floor(audioBlob.size / (1024 * 16)),
//             consultation_summaries: [
//               {
//                 ai_model_name: "llama3",
//                 summary_text: llama3Summary,
//                 code: llama3Codes,
//               },
//             ],
//             transcript: transcription,
//             is_recording_paused: false,
//             is_recording_deleted: false,
//           };
//           updateValues("modelData", payload);

//           setProcessingStep("Updating recording details...");
//           await axios.patch(API.UPDATE_RECORDING + recordingId + "/", payload, AUTH_HEADER);

//           updateValues("llama3Summary", llama3Summary);
//           updateValues("llama3Codes", llama3Codes);

//           toast.success("Processing completed successfully!");
//         } else {
//           throw new Error("Failed to process transcripts");
//         }
//       } else {
//         throw new Error("Failed to transcribe audio");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`An error occurred: ${error.message}`);
//     } finally {
//       setIsLoading(false);
//       setProcessingStep("");
//     }
//   };

//   const pauseRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "recording") {
//       mediaRecorder.pause();
//       setIsPaused(true);
//       clearInterval(recordingInterval);
//     }
//   };

//   const resumeRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "paused") {
//       mediaRecorder.resume();
//       setIsPaused(false);

//       const interval = setInterval(() => {
//         setRecordingDuration((prev) => prev + 1000);
//       }, 1000);
//       setRecordingInterval(interval);
//     }
//   };

//   const triggerStopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);
//       setIsPaused(false);
//       clearInterval(recordingInterval);
//     }
//   };

//   const deleteRecording = (e) => {
//     openDeleteModal(false);
//     if (e === false) return;
//     const recordingId = recordingData?.recordingId;
//     setActiveStep(1);
//     setMediaRecorder(null);
//     setAudioChunks([]);
//     setAudioUrl(null);
//     setIsRecording(false);
//     setIsPaused(false);
//     setRecordingDuration(0);
//     setRecordingInterval(null);
//     setRecordingData({});
//     if (!recordingId) return;
//     axios.patch(API.UPDATE_RECORDING + recordingId + "/", { is_recording_deleted: true }, AUTH_HEADER)
//       .then((res) => {
//         if (res.status === 200) toast.success(L.MSG.RECORDING_DELETED);
//       })
//       .catch(e => {
//         console.error(e);
//         toast.error("Failed to delete recording.");
//       });
//   };

//   const moveNext = () => {
//     setActiveStep(2);
//     navigate("/home/walkthrough/review", { state: { ...state, recordingId: recordingData?.recordingId } });
//   };

//   return (
//     <>
//       <ToastContainer />
//       <ConfirmationModal
//         title={L.DELETE_MODAL.TITLE}
//         text={L.DELETE_MODAL.TEXT}
//         confirmButtonText={L.DELETE_MODAL.YES}
//         cancelButtonText={L.DELETE_MODAL.NO}
//         show={deleteModal || false}
//         handleClose={deleteRecording}
//       />
//       <div className="voice-recorder">
//         <div className="head">
//           {!isRecording && !audioUrl && L.START_RECORDING}
//           {isRecording && !isPaused && L.RECORDING + "..."}
//           {isPaused && L.RESUME_RECORDING}
//           {audioUrl && !isLoading && L.COMPLETED_RECORDING}
//           {isLoading && "Processing..."}
//         </div>

//         <div className="controller">
//           {!isRecording && !audioUrl && !isLoading && (
//             <MicIcon onClick={startRecording} className="icon" />
//           )}
//           {isRecording && !isPaused && !isLoading && (
//             <PauseIcon onClick={pauseRecording} className="icon recording" />
//           )}
//           {isPaused && !isLoading && (
//             <PlayArrowIcon onClick={resumeRecording} className="icon" />
//           )}

//           {(isRecording && !audioUrl) && (
//             <div className="time">
//               {new Date(recordingDuration).toISOString().substr(11, 8)}
//             </div>
//           )}

//           {audioUrl && !isLoading && (
//             <div className="audio-display">
//               <audio
//                 src={audioUrl}
//                 ref={audioRef}
//                 onLoadedMetadata={() => setRecordingDuration(audioRef.current.duration * 1000)}
//                 hidden
//               />
//               {!audio && (<PlayArrowIcon onClick={() => toggleAudioPlay(true)} className="icon-mini" />)}
//               {audio && (<PauseIcon onClick={() => toggleAudioPlay(false)} className="icon-mini" />)}
//               <div className="slider">
//                 <div className="pad" style={{
//                   width: recordingPlayerWidth
//                 }}></div>
//               </div>
//               <div className="timer">
//                 {new Date(recordingDuration).toISOString().substr(11, 8)}
//               </div>
//             </div>
//           )}

//           {isLoading && (
//             <div className="loading-container">
//               <CircularProgress />
//               <p>{processingStep}</p>
//             </div>
//           )}
//         </div>

//         <div className="footer">
//           {(isRecording || audioUrl) && !isLoading && (
//             <>
//               <DeleteIcon
//                 className="btn"
//                 onClick={() => openDeleteModal(true)}
//               />
//               {!audioUrl && (
//                 <SaveIcon onClick={triggerStopRecording} className="btn" />
//               )}
//               {audioUrl && (
//                 <ArrowRightAltIcon className="btn" onClick={moveNext} />
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VoiceRecorder;
















// import React, { useState, useEffect, useRef } from "react";
// import "../style/VoiceRecorder.scss";
// import { toast, ToastContainer } from "react-toastify";
// import MicIcon from "@mui/icons-material/Mic";
// import { L } from "../langauge/english.lang";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ConfirmationModal from "./ConfirmationModal";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import CircularProgress from "@mui/material/CircularProgress";

// const VoiceRecorder = ({ setActiveStep, setPage }) => {
//   setPage(L.RECORD);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [audioUrl, setAudioUrl] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [recordingInterval, setRecordingInterval] = useState(null);
//   const [deleteModal, openDeleteModal] = useState(false);
//   const [recordingData, setRecordingData] = useState({});

//   const [audio, setAudio] = useState(false);
//   const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
//   const audioRef = useRef(null);
//   const [playerInterval, setPlayerInterval] = useState(null);

//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [isLoading, setIsLoading] = useState(false);
//   const [processingStep, setProcessingStep] = useState("");

//   const formatTime = (milliseconds) => {
//     const totalSeconds = Math.floor(milliseconds / 1000);
//     const minutes = Math.floor(totalSeconds / 60);
//     const seconds = totalSeconds % 60;
//     return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//   };

//   const toggleAudioPlay = (e) => {
//     if (e === true && audioRef?.current?.paused) {
//       audioRef?.current?.play();
//       const interval = setInterval(() => {
//         const val = audioRef.current?.currentTime / (recordingDuration / 1000);
//         if (val) setRecordingPlayerWidth(`${val * 100}%`);
//         if (audioRef?.current?.ended) setAudio(false);
//       }, 1000);
//       setPlayerInterval(interval);
//     } else if (e === false && !audioRef?.current?.paused) {
//       audioRef?.current?.pause();
//     }
//     setAudio(e);
//   };

//   useEffect(() => {
//     return () => {
//       if (recordingInterval) clearInterval(recordingInterval);
//       if (playerInterval) clearInterval(playerInterval);
//     };
//   }, [recordingInterval, playerInterval]);

//   const updateValues = (key, value) => {
//     setRecordingData(data => ({
//       ...data,
//       [key]: value,
//     }));
//   };

//   const startRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const recorder = new MediaRecorder(stream);
//         setMediaRecorder(recorder);

//         recorder.start();
//         setIsRecording(true);
//         setIsPaused(false);
//         setRecordingDuration(0);
//         setAudioChunks([]);

//         const interval = setInterval(() => {
//           setRecordingDuration((prev) => prev + 1000);
//         }, 1000);
//         setRecordingInterval(interval);

//         recorder.ondataavailable = (event) => {
//           const chunks = [...audioChunks, event?.data];
//           setAudioChunks(chunks);
//           const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
//           setAudioUrl(URL.createObjectURL(audioBlob));
//           clearInterval(interval);
//           setRecordingInterval(null);

//           processRecording(audioBlob);
//         };
//       })
//       .catch((error) => {
//         console.error("Error accessing microphone:", error);
//         toast.error("Failed to access microphone. Please check your permissions.");
//       });
//   };

//   const processRecording = async (audioBlob) => {
//     setIsLoading(true);
//     setProcessingStep("Initiating processing...");

//     try {
//       const transcribeFD = new FormData();
//       transcribeFD.append(
//         "file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       const backendFD = new FormData();
//       backendFD.append(
//         "recording_file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       backendFD.append("appointment", state.apt?.id);
//       backendFD.append(
//         "recording_duration",
//         Math.floor(recordingDuration / 1000)
//       );

//       setProcessingStep("Saving recording...");
//       const recordingResponse = await axios.post(API.POST_RECORDING, backendFD, AUTH_HEADER);
//       const recordingId = recordingResponse?.data?.data?.id;
//       updateValues("recordingId", recordingId);
//       toast.success(L.MSG.CONVERSATION_SAVED);

//       setProcessingStep("Transcribing audio...");
//       const transcribeResponse = await fetch(
//         "https://aisummarizer.cloud/transcribe/audio-text/",
//         {
//           method: "POST",
//           body: transcribeFD,
//           headers: {
//             accept: "application/json",
//           },
//         }
//       );

//       if (transcribeResponse.ok) {
//         const data = await transcribeResponse.json();
//         const transcription = data.transcription;
//         updateValues("transcription", transcription);

//         setProcessingStep("Analyzing with Llama3...");
//         const processLlama3 = await fetch(
//           "https://aisummarizer.cloud/transcript/process_transcript",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               accept: "application/json",
//             },
//             body: JSON.stringify({
//               transcript: transcription,
//               model: "llama3",
//             }),
//           }
//         );

//         if (processLlama3.ok) {
//           const llama3Data = await processLlama3.json();
//           const llama3Summary = llama3Data.summary;
//           const llama3Codes = JSON.stringify(llama3Data.codes);

//           const payload = {
//             recording_duration: Math.floor(recordingDuration / 1000),
//             consultation_summaries: [
//               {
//                 ai_model_name: "llama3",
//                 summary_text: llama3Summary,
//                 code: llama3Codes,
//               },
//             ],
//             transcript: transcription,
//             is_recording_paused: false,
//             is_recording_deleted: false,
//           };
//           updateValues("modelData", payload);

//           setProcessingStep("Updating recording details...");
//           await axios.patch(API.UPDATE_RECORDING + recordingId + "/", payload, AUTH_HEADER);

//           updateValues("llama3Summary", llama3Summary);
//           updateValues("llama3Codes", llama3Codes);

//           toast.success("Processing completed successfully!");
//         } else {
//           throw new Error("Failed to process transcripts");
//         }
//       } else {
//         throw new Error("Failed to transcribe audio");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`An error occurred: ${error.message}`);
//     } finally {
//       setIsLoading(false);
//       setProcessingStep("");
//     }
//   };

//   const pauseRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "recording") {
//       mediaRecorder.pause();
//       setIsPaused(true);
//       clearInterval(recordingInterval);
//     }
//   };

//   const resumeRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "paused") {
//       mediaRecorder.resume();
//       setIsPaused(false);

//       const interval = setInterval(() => {
//         setRecordingDuration((prev) => prev + 1000);
//       }, 1000);
//       setRecordingInterval(interval);
//     }
//   };

//   const triggerStopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);
//       setIsPaused(false);
//       clearInterval(recordingInterval);
//     }
//   };

//   const deleteRecording = (e) => {
//     openDeleteModal(false);
//     if (e === false) return;
//     const recordingId = recordingData?.recordingId;
//     setActiveStep(1);
//     setMediaRecorder(null);
//     setAudioChunks([]);
//     setAudioUrl(null);
//     setIsRecording(false);
//     setIsPaused(false);
//     setRecordingDuration(0);
//     setRecordingInterval(null);
//     setRecordingData({});
//     if (!recordingId) return;
//     axios.patch(API.UPDATE_RECORDING + recordingId + "/", { is_recording_deleted: true }, AUTH_HEADER)
//       .then((res) => {
//         if (res.status === 200) toast.success(L.MSG.RECORDING_DELETED);
//       })
//       .catch(e => {
//         console.error(e);
//         toast.error("Failed to delete recording.");
//       });
//   };

//   const moveNext = () => {
//     setActiveStep(2);
//     navigate("/home/walkthrough/review", { state: { ...state, recordingId: recordingData?.recordingId } });
//   };

//   return (
//     <>
//       <ToastContainer />
//       <ConfirmationModal
//         title={L.DELETE_MODAL.TITLE}
//         text={L.DELETE_MODAL.TEXT}
//         confirmButtonText={L.DELETE_MODAL.YES}
//         cancelButtonText={L.DELETE_MODAL.NO}
//         show={deleteModal || false}
//         handleClose={deleteRecording}
//       />
//       <div className="voice-recorder">
//         <div className="head">
//           {!isRecording && !audioUrl && L.START_RECORDING}
//           {isRecording && !isPaused && L.RECORDING + "..."}
//           {isPaused && L.RESUME_RECORDING}
//           {audioUrl && !isLoading && L.COMPLETED_RECORDING}
//           {isLoading && "Processing..."}
//         </div>

//         <div className="controller">
//           {!isRecording && !audioUrl && !isLoading && (
//             <MicIcon onClick={startRecording} className="icon" />
//           )}
//           {isRecording && !isPaused && !isLoading && (
//             <PauseIcon onClick={pauseRecording} className="icon recording" />
//           )}
//           {isPaused && !isLoading && (
//             <PlayArrowIcon onClick={resumeRecording} className="icon" />
//           )}

//           {(isRecording && !audioUrl) && (
//             <div className="time">
//               {formatTime(recordingDuration)}
//             </div>
//           )}

//           {audioUrl && !isLoading && (
//             <div className="audio-display">
//               <audio
//                 src={audioUrl}
//                 ref={audioRef}
//                 onLoadedMetadata={() => setRecordingDuration(audioRef.current.duration * 1000)}
//                 hidden
//               />
//               {!audio && (<PlayArrowIcon onClick={() => toggleAudioPlay(true)} className="icon-mini" />)}
//               {audio && (<PauseIcon onClick={() => toggleAudioPlay(false)} className="icon-mini" />)}
//               <div className="slider">
//                 <div className="pad" style={{
//                   width: recordingPlayerWidth
//                 }}></div>
//               </div>
//               <div className="timer">
//                 {formatTime(recordingDuration)}
//               </div>
//             </div>
//           )}

//           {isLoading && (
//             <div className="loading-container">
//               <CircularProgress />
//               <p>{processingStep}</p>
//             </div>
//           )}
//         </div>

//         <div className="footer">
//           {(isRecording || audioUrl) && !isLoading && (
//             <>
//               <DeleteIcon
//                 className="btn"
//                 onClick={() => openDeleteModal(true)}
//               />
//               {!audioUrl && (
//                 <SaveIcon onClick={triggerStopRecording} className="btn" />
//               )}
//               {audioUrl && (
//                 <ArrowRightAltIcon className="btn" onClick={moveNext} />
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VoiceRecorder;






// import React, { useState, useEffect, useRef } from "react";
// import "../style/VoiceRecorder.scss";
// import { toast, ToastContainer } from "react-toastify";
// import MicIcon from "@mui/icons-material/Mic";
// import { L } from "../langauge/english.lang";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ConfirmationModal from "./ConfirmationModal";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import CircularProgress from "@mui/material/CircularProgress";

// const VoiceRecorder = ({ setActiveStep, setPage }) => {
//   setPage(L.RECORD);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [audioUrl, setAudioUrl] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [recordingInterval, setRecordingInterval] = useState(null);
//   const [deleteModal, openDeleteModal] = useState(false);
//   const [recordingData, setRecordingData] = useState({});

//   const [audio, setAudio] = useState(false);
//   const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
//   const audioRef = useRef(null);
//   const [playerInterval, setPlayerInterval] = useState(null);

//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [isLoading, setIsLoading] = useState(false);
//   const [processingStep, setProcessingStep] = useState("");

//   const formatTime = (milliseconds) => {
//     if (!milliseconds || isNaN(milliseconds) || milliseconds === Infinity) {
//       return "00:00";
//     }
//     const totalSeconds = Math.floor(milliseconds / 1000);
//     const minutes = Math.floor(totalSeconds / 60);
//     const seconds = totalSeconds % 60;
//     return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//   };

//   const toggleAudioPlay = (e) => {
//     if (e === true && audioRef?.current?.paused) {
//       audioRef?.current?.play();
//       const interval = setInterval(() => {
//         const currentTime = audioRef.current?.currentTime || 0;
//         const duration = audioRef.current?.duration || 0;
//         if (duration > 0) {
//           setRecordingPlayerWidth(`${(currentTime / duration) * 100}%`);
//         }
//         if (audioRef?.current?.ended) {
//           setAudio(false);
//           clearInterval(interval);
//         }
//       }, 100);
//       setPlayerInterval(interval);
//     } else if (e === false && !audioRef?.current?.paused) {
//       audioRef?.current?.pause();
//       clearInterval(playerInterval);
//     }
//     setAudio(e);
//   };

//   useEffect(() => {
//     return () => {
//       if (recordingInterval) clearInterval(recordingInterval);
//       if (playerInterval) clearInterval(playerInterval);
//     };
//   }, [recordingInterval, playerInterval]);

//   const updateValues = (key, value) => {
//     setRecordingData(data => ({
//       ...data,
//       [key]: value,
//     }));
//   };

//   const startRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const recorder = new MediaRecorder(stream);
//         setMediaRecorder(recorder);

//         recorder.start();
//         setIsRecording(true);
//         setIsPaused(false);
//         setRecordingDuration(0);
//         setAudioChunks([]);

//         const interval = setInterval(() => {
//           setRecordingDuration((prev) => prev + 1000);
//         }, 1000);
//         setRecordingInterval(interval);

//         recorder.ondataavailable = (event) => {
//           const chunks = [...audioChunks, event?.data];
//           setAudioChunks(chunks);
//           const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
//           const url = URL.createObjectURL(audioBlob);
//           setAudioUrl(url);
//           clearInterval(interval);
//           setRecordingInterval(null);

//           // Use Web Audio API to get accurate duration
//           const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//           audioContext.decodeAudioData(audioBlob.arrayBuffer(), (buffer) => {
//             const duration = buffer.duration * 1000; // Convert to milliseconds
//             setRecordingDuration(duration);
//             processRecording(audioBlob, duration);
//           });
//         };
//       })
//       .catch((error) => {
//         console.error("Error accessing microphone:", error);
//         toast.error("Failed to access microphone. Please check your permissions.");
//       });
//   };

//   const processRecording = async (audioBlob, duration) => {
//     setIsLoading(true);
//     setProcessingStep("Initiating processing...");

//     try {
//       const transcribeFD = new FormData();
//       transcribeFD.append(
//         "file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       const backendFD = new FormData();
//       backendFD.append(
//         "recording_file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       backendFD.append("appointment", state.apt?.id);
//       backendFD.append(
//         "recording_duration",
//         Math.floor(duration / 1000)
//       );

//       setProcessingStep("Saving recording...");
//       const recordingResponse = await axios.post(API.POST_RECORDING, backendFD, AUTH_HEADER);
//       const recordingId = recordingResponse?.data?.data?.id;
//       updateValues("recordingId", recordingId);
//       toast.success(L.MSG.CONVERSATION_SAVED);

//       setProcessingStep("Transcribing audio...");
//       const transcribeResponse = await fetch(
//         "https://aisummarizer.cloud/transcribe/audio-text/",
//         {
//           method: "POST",
//           body: transcribeFD,
//           headers: {
//             accept: "application/json",
//           },
//         }
//       );

//       if (transcribeResponse.ok) {
//         const data = await transcribeResponse.json();
//         const transcription = data.transcription;
//         updateValues("transcription", transcription);

//         setProcessingStep("Analyzing with Llama3...");
//         const processLlama3 = await fetch(
//           "https://aisummarizer.cloud/transcript/process_transcript",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               accept: "application/json",
//             },
//             body: JSON.stringify({
//               transcript: transcription,
//               model: "llama3",
//             }),
//           }
//         );

//         if (processLlama3.ok) {
//           const llama3Data = await processLlama3.json();
//           const llama3Summary = llama3Data.summary;
//           const llama3Codes = JSON.stringify(llama3Data.codes);

//           const payload = {
//             recording_duration: Math.floor(duration / 1000),
//             consultation_summaries: [
//               {
//                 ai_model_name: "llama3",
//                 summary_text: llama3Summary,
//                 code: llama3Codes,
//               },
//             ],
//             transcript: transcription,
//             is_recording_paused: false,
//             is_recording_deleted: false,
//           };
//           updateValues("modelData", payload);

//           setProcessingStep("Updating recording details...");
//           await axios.patch(API.UPDATE_RECORDING + recordingId + "/", payload, AUTH_HEADER);

//           updateValues("llama3Summary", llama3Summary);
//           updateValues("llama3Codes", llama3Codes);

//           toast.success("Processing completed successfully!");
//         } else {
//           throw new Error("Failed to process transcripts");
//         }
//       } else {
//         throw new Error("Failed to transcribe audio");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`An error occurred: ${error.message}`);
//     } finally {
//       setIsLoading(false);
//       setProcessingStep("");
//     }
//   };

//   const pauseRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "recording") {
//       mediaRecorder.pause();
//       setIsPaused(true);
//       clearInterval(recordingInterval);
//     }
//   };

//   const resumeRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "paused") {
//       mediaRecorder.resume();
//       setIsPaused(false);

//       const interval = setInterval(() => {
//         setRecordingDuration((prev) => prev + 1000);
//       }, 1000);
//       setRecordingInterval(interval);
//     }
//   };

//   const triggerStopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);
//       setIsPaused(false);
//       clearInterval(recordingInterval);
//     }
//   };

//   const deleteRecording = (e) => {
//     openDeleteModal(false);
//     if (e === false) return;
//     const recordingId = recordingData?.recordingId;
//     setActiveStep(1);
//     setMediaRecorder(null);
//     setAudioChunks([]);
//     setAudioUrl(null);
//     setIsRecording(false);
//     setIsPaused(false);
//     setRecordingDuration(0);
//     setRecordingInterval(null);
//     setRecordingData({});
//     if (!recordingId) return;
//     axios.patch(API.UPDATE_RECORDING + recordingId + "/", { is_recording_deleted: true }, AUTH_HEADER)
//       .then((res) => {
//         if (res.status === 200) toast.success(L.MSG.RECORDING_DELETED);
//       })
//       .catch(e => {
//         console.error(e);
//         toast.error("Failed to delete recording.");
//       });
//   };

//   const moveNext = () => {
//     setActiveStep(2);
//     navigate("/home/walkthrough/review", { state: { ...state, recordingId: recordingData?.recordingId } });
//   };

//   return (
//     <>
//       <ToastContainer />
//       <ConfirmationModal
//         title={L.DELETE_MODAL.TITLE}
//         text={L.DELETE_MODAL.TEXT}
//         confirmButtonText={L.DELETE_MODAL.YES}
//         cancelButtonText={L.DELETE_MODAL.NO}
//         show={deleteModal || false}
//         handleClose={deleteRecording}
//       />
//       <div className="voice-recorder">
//         <div className="head">
//           {!isRecording && !audioUrl && L.START_RECORDING}
//           {isRecording && !isPaused && L.RECORDING + "..."}
//           {isPaused && L.RESUME_RECORDING}
//           {audioUrl && !isLoading && L.COMPLETED_RECORDING}
//           {isLoading && "Processing..."}
//         </div>

//         <div className="controller">
//           {!isRecording && !audioUrl && !isLoading && (
//             <MicIcon onClick={startRecording} className="icon" />
//           )}
//           {isRecording && !isPaused && !isLoading && (
//             <PauseIcon onClick={pauseRecording} className="icon recording" />
//           )}
//           {isPaused && !isLoading && (
//             <PlayArrowIcon onClick={resumeRecording} className="icon" />
//           )}

//           {(isRecording && !audioUrl) && (
//             <div className="time">
//               {formatTime(recordingDuration)}
//             </div>
//           )}

//           {audioUrl && !isLoading && (
//             <div className="audio-display">
//               <audio
//                 src={audioUrl}
//                 ref={audioRef}
//                 onLoadedMetadata={() => {
//                   if (audioRef.current) {
//                     setRecordingDuration(audioRef.current.duration * 1000);
//                   }
//                 }}
//                 hidden
//               />
//               {!audio && (<PlayArrowIcon onClick={() => toggleAudioPlay(true)} className="icon-mini" />)}
//               {audio && (<PauseIcon onClick={() => toggleAudioPlay(false)} className="icon-mini" />)}
//               <div className="slider">
//                 <div className="pad" style={{
//                   width: recordingPlayerWidth
//                 }}></div>
//               </div>
//               <div className="timer">
//                 {formatTime(recordingDuration)}
//               </div>
//             </div>
//           )}

//           {isLoading && (
//             <div className="loading-container">
//               <CircularProgress />
//               <p>{processingStep}</p>
//             </div>
//           )}
//         </div>

//         <div className="footer">
//           {(isRecording || audioUrl) && !isLoading && (
//             <>
//               <DeleteIcon
//                 className="btn"
//                 onClick={() => openDeleteModal(true)}
//               />
//               {!audioUrl && (
//                 <SaveIcon onClick={triggerStopRecording} className="btn" />
//               )}
//               {audioUrl && (
//                 <ArrowRightAltIcon className="btn" onClick={moveNext} />
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VoiceRecorder;




// import React, { useState, useEffect, useRef } from "react";
// import "../style/VoiceRecorder.scss";
// import { toast, ToastContainer } from "react-toastify";
// import MicIcon from "@mui/icons-material/Mic";
// import { L } from "../langauge/english.lang";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ConfirmationModal from "./ConfirmationModal";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import CircularProgress from "@mui/material/CircularProgress";

// const VoiceRecorder = ({ setActiveStep, setPage }) => {
//   setPage(L.RECORD);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [audioUrl, setAudioUrl] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [recordingInterval, setRecordingInterval] = useState(null);
//   const [deleteModal, openDeleteModal] = useState(false);
//   const [recordingData, setRecordingData] = useState({});

//   const [audio, setAudio] = useState(false);
//   const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
//   const audioRef = useRef(null);
//   const [playerInterval, setPlayerInterval] = useState(null);

//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [isLoading, setIsLoading] = useState(false);
//   const [processingStep, setProcessingStep] = useState("");

//   // const formatTime = (milliseconds) => {
//   //   if (!milliseconds || isNaN(milliseconds) || milliseconds === Infinity) {
//   //     return "00:00";
//   //   }
//   //   const totalSeconds = Math.floor(milliseconds / 1000);
//   //   const minutes = Math.floor(totalSeconds / 60);
//   //   const seconds = totalSeconds % 60;
//   //   return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//   // };

//   const formatTime = (milliseconds) => {
//     const totalSeconds = Math.floor(milliseconds / 1000);
//     const minutes = Math.floor(totalSeconds / 60);
//     const seconds = totalSeconds % 60;
//     return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//   };

//   const toggleAudioPlay = (e) => {
//     if (e === true && audioRef?.current?.paused) {
//       audioRef?.current?.play();
//       const interval = setInterval(() => {
//         const currentTime = audioRef.current?.currentTime || 0;
//         const duration = audioRef.current?.duration || 0;
//         if (duration > 0) {
//           setRecordingPlayerWidth(`${(currentTime / duration) * 100}%`);
//         }
//         if (audioRef?.current?.ended) {
//           setAudio(false);
//           clearInterval(interval);
//         }
//       }, 100);
//       setPlayerInterval(interval);
//     } else if (e === false && !audioRef?.current?.paused) {
//       audioRef?.current?.pause();
//       clearInterval(playerInterval);
//     }
//     setAudio(e);
//   };

//   useEffect(() => {
//     return () => {
//       if (recordingInterval) clearInterval(recordingInterval);
//       if (playerInterval) clearInterval(playerInterval);
//     };
//   }, [recordingInterval, playerInterval]);

//   const updateValues = (key, value) => {
//     setRecordingData(data => ({
//       ...data,
//       [key]: value,
//     }));
//   };

//   const startRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const recorder = new MediaRecorder(stream);
//         setMediaRecorder(recorder);

//         recorder.start();
//         setIsRecording(true);
//         setIsPaused(false);
//         setRecordingDuration(0);
//         setAudioChunks([]);

//         const interval = setInterval(() => {
//           setRecordingDuration((prev) => prev + 1000);
//         }, 1000);
//         setRecordingInterval(interval);

//         recorder.ondataavailable = (event) => {
//           const chunks = [...audioChunks, event?.data];
//           setAudioChunks(chunks);
//           const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
//           const url = URL.createObjectURL(audioBlob);
//           setAudioUrl(url);
//           clearInterval(interval);
//           setRecordingInterval(null);

//           // Use FileReader to read the Blob as ArrayBuffer
//           const reader = new FileReader();
//           reader.onload = function(e) {
//             const arrayBuffer = e.target.result;
//             // Use Web Audio API to get accurate duration
//             const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//             audioContext.decodeAudioData(arrayBuffer, (buffer) => {
//               const duration = buffer.duration * 1000; // Convert to milliseconds
//               setRecordingDuration(duration);
//               processRecording(audioBlob, duration);
//             }, (error) => {
//               console.error("Error decoding audio data:", error);
//               toast.error("Error processing audio. Please try again.");
//             });
//           };
//           reader.onerror = function(error) {
//             console.error("Error reading file:", error);
//             toast.error("Error processing audio. Please try again.");
//           };
//           reader.readAsArrayBuffer(audioBlob);
//         };
//       })
//       .catch((error) => {
//         console.error("Error accessing microphone:", error);
//         toast.error("Failed to access microphone. Please check your permissions.");
//       });
//   };

//   const processRecording = async (audioBlob, duration) => {
//     setIsLoading(true);
//     setProcessingStep("Initiating processing...");

//     try {
//       const transcribeFD = new FormData();
//       transcribeFD.append(
//         "file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       const backendFD = new FormData();
//       backendFD.append(
//         "recording_file",
//         new File([audioBlob], "recording.wav", { type: "audio/wav" })
//       );
//       backendFD.append("appointment", state.apt?.id);
//       backendFD.append(
//         "recording_duration",
//         Math.floor(duration / 1000)
//       );

//       setProcessingStep("Saving recording...");
//       const recordingResponse = await axios.post(API.POST_RECORDING, backendFD, AUTH_HEADER);
//       const recordingId = recordingResponse?.data?.data?.id;
//       updateValues("recordingId", recordingId);
//       toast.success(L.MSG.CONVERSATION_SAVED);

//       setProcessingStep("Transcribing audio...");
//       const transcribeResponse = await fetch(
//         "https://aisummarizer.cloud/transcribe/audio-text/",
//         {
//           method: "POST",
//           body: transcribeFD,
//           headers: {
//             accept: "application/json",
//           },
//         }
//       );

//       if (transcribeResponse.ok) {
//         const data = await transcribeResponse.json();
//         const transcription = data.transcription;
//         updateValues("transcription", transcription);

//         setProcessingStep("Analyzing with Llama3...");
//         const processLlama3 = await fetch(
//           "https://aisummarizer.cloud/transcript/process_transcript",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               accept: "application/json",
//             },
//             body: JSON.stringify({
//               transcript: transcription,
//               model: "llama3",
//             }),
//           }
//         );

//         if (processLlama3.ok) {
//           const llama3Data = await processLlama3.json();
//           const llama3Summary = llama3Data.summary;
//           const llama3Codes = JSON.stringify(llama3Data.codes);

//           const payload = {
//             recording_duration: Math.floor(duration / 1000),
//             consultation_summaries: [
//               {
//                 ai_model_name: "llama3",
//                 summary_text: llama3Summary,
//                 code: llama3Codes,
//               },
//             ],
//             transcript: transcription,
//             is_recording_paused: false,
//             is_recording_deleted: false,
//           };
//           updateValues("modelData", payload);

//           setProcessingStep("Updating recording details...");
//           await axios.patch(API.UPDATE_RECORDING + recordingId + "/", payload, AUTH_HEADER);

//           updateValues("llama3Summary", llama3Summary);
//           updateValues("llama3Codes", llama3Codes);

//           toast.success("Processing completed successfully!");
//         } else {
//           throw new Error("Failed to process transcripts");
//         }
//       } else {
//         throw new Error("Failed to transcribe audio");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`An error occurred: ${error.message}`);
//     } finally {
//       setIsLoading(false);
//       setProcessingStep("");
//     }
//   };

//   const pauseRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "recording") {
//       mediaRecorder.pause();
//       setIsPaused(true);
//       clearInterval(recordingInterval);
//     }
//   };

//   const resumeRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === "paused") {
//       mediaRecorder.resume();
//       setIsPaused(false);

//       const interval = setInterval(() => {
//         setRecordingDuration((prev) => prev + 1000);
//       }, 1000);
//       setRecordingInterval(interval);
//     }
//   };

//   const triggerStopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);
//       setIsPaused(false);
//       clearInterval(recordingInterval);
//     }
//   };

//   const deleteRecording = (e) => {
//     openDeleteModal(false);
//     if (e === false) return;
//     const recordingId = recordingData?.recordingId;
//     setActiveStep(1);
//     setMediaRecorder(null);
//     setAudioChunks([]);
//     setAudioUrl(null);
//     setIsRecording(false);
//     setIsPaused(false);
//     setRecordingDuration(0);
//     setRecordingInterval(null);
//     setRecordingData({});
//     if (!recordingId) return;
//     axios.patch(API.UPDATE_RECORDING + recordingId + "/", { is_recording_deleted: true }, AUTH_HEADER)
//       .then((res) => {
//         if (res.status === 200) toast.success(L.MSG.RECORDING_DELETED);
//       })
//       .catch(e => {
//         console.error(e);
//         toast.error("Failed to delete recording.");
//       });
//   };

//   const moveNext = () => {
//     setActiveStep(2);
//     navigate("/home/walkthrough/review", { state: { ...state, recordingId: recordingData?.recordingId } });
//   };

//   return (
//     <>
//       <ToastContainer />
//       <ConfirmationModal
//         title={L.DELETE_MODAL.TITLE}
//         text={L.DELETE_MODAL.TEXT}
//         confirmButtonText={L.DELETE_MODAL.YES}
//         cancelButtonText={L.DELETE_MODAL.NO}
//         show={deleteModal || false}
//         handleClose={deleteRecording}
//       />
//       <div className="voice-recorder">
//         <div className="head">
//           {!isRecording && !audioUrl && L.START_RECORDING}
//           {isRecording && !isPaused && L.RECORDING + "..."}
//           {isPaused && L.RESUME_RECORDING}
//           {audioUrl && !isLoading && L.COMPLETED_RECORDING}
//           {isLoading && "Processing..."}
//         </div>

//         <div className="controller">
//           {!isRecording && !audioUrl && !isLoading && (
//             <MicIcon onClick={startRecording} className="icon" />
//           )}
//           {isRecording && !isPaused && !isLoading && (
//             <PauseIcon onClick={pauseRecording} className="icon recording" />
//           )}
//           {isPaused && !isLoading && (
//             <PlayArrowIcon onClick={resumeRecording} className="icon" />
//           )}

//           {(isRecording && !audioUrl) && (
//             <div className="time">
//               {formatTime(recordingDuration)}
//             </div>
//           )}

//           {audioUrl && !isLoading && (
//             <div className="audio-display">
//               <audio
//                 src={audioUrl}
//                 ref={audioRef}
//                 onLoadedMetadata={() => {
//                   if (audioRef.current) {
//                     setRecordingDuration(audioRef.current.duration * 1000);
//                   }
//                 }}
//                 hidden
//               />
//               {!audio && (<PlayArrowIcon onClick={() => toggleAudioPlay(true)} className="icon-mini" />)}
//               {audio && (<PauseIcon onClick={() => toggleAudioPlay(false)} className="icon-mini" />)}
//               <div className="slider">
//                 <div className="pad" style={{
//                   width: recordingPlayerWidth
//                 }}></div>
//               </div>
//               <div className="timer">
//                 {formatTime(recordingDuration)}
//               </div>
//             </div>
//           )}

//           {isLoading && (
//             <div className="loading-container">
//               <CircularProgress />
//               <p>{processingStep}</p>
//             </div>
//           )}
//         </div>

//         <div className="footer">
//           {(isRecording || audioUrl) && !isLoading && (
//             <>
//               <DeleteIcon
//                 className="btn"
//                 onClick={() => openDeleteModal(true)}
//               />
//               {!audioUrl && (
//                 <SaveIcon onClick={triggerStopRecording} className="btn" />
//               )}
//               {audioUrl && (
//                 <ArrowRightAltIcon className="btn" onClick={moveNext} />
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VoiceRecorder;



import React, { useState, useEffect, useRef } from "react";
import "../style/VoiceRecorder.scss";
import { toast, ToastContainer } from "react-toastify";
import MicIcon from "@mui/icons-material/Mic";
import { L } from "../langauge/english.lang";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ConfirmationModal from "./ConfirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API, AUTH_HEADER } from "../constants/api.const";
import CircularProgress from "@mui/material/CircularProgress";

const VoiceRecorder = ({ setActiveStep, setPage }) => {
  setPage(L.RECORD);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [recordingInterval, setRecordingInterval] = useState(null);
  const [deleteModal, openDeleteModal] = useState(false);
  const [recordingData, setRecordingData] = useState({});

  const [audio, setAudio] = useState(false);
  const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
  const audioRef = useRef(null);
  const [playerInterval, setPlayerInterval] = useState(null);

  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [processingStep, setProcessingStep] = useState("");

  const formatTime = (milliseconds) => {
    if (!milliseconds || isNaN(milliseconds) || milliseconds === Infinity) {
      return "00:00";
    }
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const toggleAudioPlay = (e) => {
    if (e === true && audioRef?.current?.paused) {
      audioRef?.current?.play();
      const interval = setInterval(() => {
        const currentTime = audioRef.current?.currentTime || 0;
        const duration = recordingDuration/1000;
        if (duration > 0) {
          setRecordingPlayerWidth(`${(currentTime / duration) * 100}%`);
        }
        if (audioRef?.current?.ended) {
          setAudio(false);
          clearInterval(interval);
        }
      }, 100);
      setPlayerInterval(interval);
    } else if (e === false && !audioRef?.current?.paused) {
      audioRef?.current?.pause();
      clearInterval(playerInterval);
    }
    setAudio(e);
  };

  useEffect(() => {
    return () => {
      if (recordingInterval) clearInterval(recordingInterval);
      if (playerInterval) clearInterval(playerInterval);
    };
  }, [recordingInterval, playerInterval]);

  const updateValues = (key, value) => {
    setRecordingData((data) => ({
      ...data,
      [key]: value,
    }));
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.start();
        setIsRecording(true);
        setIsPaused(false);
        setRecordingDuration(0);
        setAudioChunks([]);

        const interval = setInterval(() => {
          setRecordingDuration((prev) => prev + 1000);
        }, 1000);
        setRecordingInterval(interval);

        recorder.ondataavailable = (event) => {
          const chunks = [...audioChunks, event.data];
          setAudioChunks(chunks);
          const audioBlob = new Blob(chunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url);
          clearInterval(interval);
          setRecordingInterval(null);
        };
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        toast.error(
          "Failed to access microphone. Please check your permissions."
        );
      });
  };

  const processRecording = async (audioBlob, duration) => {
    setIsLoading(true);
    setProcessingStep("Initiating processing...");

    try {
      const transcribeFD = new FormData();
      transcribeFD.append(
        "file",
        new File([audioBlob], "recording.wav", { type: "audio/wav" })
      );
      const backendFD = new FormData();
      backendFD.append(
        "recording_file",
        new File([audioBlob], "recording.wav", { type: "audio/wav" })
      );
      backendFD.append("appointment", state.apt?.id);
      backendFD.append("recording_duration", Math.floor(duration / 1000));

      setProcessingStep("Saving recording...");
      const recordingResponse = await axios.post(
        API.POST_RECORDING,
        backendFD,
        AUTH_HEADER
      );
      const recordingId = recordingResponse?.data?.data?.id;
      updateValues("recordingId", recordingId);
      toast.success(L.MSG.CONVERSATION_SAVED);

      setProcessingStep("Analyzing with AI...");
      const transcribeResponse = await fetch(
        "https://aisummarizer.cloud/transcribe/audio-text/",
        {
          method: "POST",
          body: transcribeFD,
          headers: {
            accept: "application/json",
          },
        }
      );

      if (transcribeResponse.ok) {
        const data = await transcribeResponse.json();
        const transcription = data.transcription;
        const genderDict = data.gender_dict;

        updateValues("transcription", transcription);

         setProcessingStep("Analyzing with AI...");
        const processLlama3 = await fetch(
          "https://aisummarizer.cloud/transcript/process_transcript",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            body: JSON.stringify({
              transcript: transcription,
              model: "llama3",
              duration: duration/1000,
              gender_dict:genderDict,
            }),
          }
        );

        if (processLlama3.ok) {
          const llama3Data = await processLlama3.json();
          const llama3Summary = llama3Data.summary;
          const llama3Codes = JSON.stringify(llama3Data.codes);

          const payload = {
            recording_duration: Math.floor(duration / 1000),
            consultation_summaries: [
              {
                ai_model_name: "llama3",
                summary_text: llama3Summary,
                code: llama3Codes,
              },
            ],
            transcript: transcription,
            is_recording_paused: false,
            is_recording_deleted: false,
          };
          updateValues("modelData", payload);

          setProcessingStep("Updating recording details...");
          await axios.patch(
            API.UPDATE_RECORDING + recordingId + "/",
            payload,
            AUTH_HEADER
          );

          updateValues("llama3Summary", llama3Summary);
          updateValues("llama3Codes", llama3Codes);

          toast.success("Processing completed successfully!");
        } else {
          throw new Error("Failed to process transcripts");
        }
      } else {
        throw new Error("Failed to transcribe audio");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
      setProcessingStep("");
    }
  };

  useEffect(() => {
    if (audioBlob && recordingDuration) {
      processRecording(audioBlob, recordingDuration);
    }
  }, [audioBlob, recordingDuration]);

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      clearInterval(recordingInterval);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);

      const interval = setInterval(() => {
        setRecordingDuration((prev) => prev + 1000);
      }, 1000);
      setRecordingInterval(interval);
    }
  };

  const triggerStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      setIsPaused(false);
      clearInterval(recordingInterval);
    }
  };

  const deleteRecording = (e) => {
    openDeleteModal(false);
    if (e === false) return;
    const recordingId = recordingData?.recordingId;
    setActiveStep(1);
    setMediaRecorder(null);
    setAudioChunks([]);
    setAudioUrl(null);
    setAudioBlob(null);
    setIsRecording(false);
    setIsPaused(false);
    setRecordingDuration(0);
    setRecordingInterval(null);
    setRecordingData({});
    if (!recordingId) return;
    axios
      .patch(
        API.UPDATE_RECORDING + recordingId + "/",
        { is_recording_deleted: true },
        AUTH_HEADER
      )
      .then((res) => {
        if (res.status === 200) toast.success(L.MSG.RECORDING_DELETED);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Failed to delete recording.");
      });
  };

  const moveNext = () => {
    setActiveStep(2);
    navigate("/home/walkthrough/review", {
      state: { ...state, recordingId: recordingData?.recordingId },
    });
  };

  // console.log(recordingDuration, recordingDuration);

  return (
    <>
      <ToastContainer />
      <ConfirmationModal
        title={L.DELETE_MODAL.TITLE}
        text={L.DELETE_MODAL.TEXT}
        confirmButtonText={L.DELETE_MODAL.YES}
        cancelButtonText={L.DELETE_MODAL.NO}
        show={deleteModal || false}
        handleClose={deleteRecording}
      />
      <div className="voice-recorder">
        <div className="head">
          {!isRecording && !audioUrl && L.START_RECORDING}
          {isRecording && !isPaused && L.RECORDING + "..."}
          {isPaused && L.RESUME_RECORDING}
          {audioUrl && !isLoading && L.COMPLETED_RECORDING}
          {isLoading && "Processing..."}
        </div>

        <div className="controller">
          {!isRecording && !audioUrl && !isLoading && (
            <MicIcon onClick={startRecording} className="icon" />
          )}
          {isRecording && !isPaused && !isLoading && (
            <PauseIcon
              onClick={pauseRecording}
              className="icon recording"
            />
          )}
          {isPaused && !isLoading && (
            <PlayArrowIcon onClick={resumeRecording} className="icon" />
          )}
          
          {isRecording && !audioUrl && (
            <div className="time">{formatTime(recordingDuration)}</div>
          )}

          {audioUrl && !isLoading && (
            <div className="audio-display">
              <audio
                src={audioUrl}
                ref={audioRef}
                // onLoadedMetadata={() => {
                //   if (audioRef.current) {
                //     setRecordingDuration(
                //       audioRef.current.duration * 1000
                //     );
                //   }
                // }}
                hidden
              />
              {!audio && (
                <PlayArrowIcon
                  onClick={() => toggleAudioPlay(true)}
                  className="icon-mini"
                />
              )}
              {audio && (
                <PauseIcon
                  onClick={() => toggleAudioPlay(false)}
                  className="icon-mini"
                />
              )}
              <div className="slider">
                <div
                  className="pad"
                  style={{
                    width: recordingPlayerWidth,
                  }}
                ></div>
              </div>
              <div className="timer">
                {formatTime(recordingDuration)}
              </div>
            </div>
          )}

          {isLoading && (
            <div style={{
              display:'flex',
              alignItems : 'center',
              flexDirection : 'column'
            }} >
              <CircularProgress />
              <p>{processingStep}</p>
            </div>
          )}
        </div>

        <div className="footer">
          {(isRecording || audioUrl) && !isLoading && (
            <>
              <DeleteIcon
                className="btn"
                onClick={() => openDeleteModal(true)}
              />
              {!audioUrl && (
                <SaveIcon onClick={triggerStopRecording} className="btn" />
              )}
              {audioUrl && (
                <ArrowRightAltIcon className="btn" onClick={moveNext} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VoiceRecorder;
