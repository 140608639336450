import React, { useEffect, useState } from "react";
import "../style/auth.scss";
import * as formik from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import MicrosoftAuthComponent from "../component/Auth/MicrosoftAuthComponent";

const SignIn = () => {
  const { Formik } = formik;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [navigate]);

  const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmitData = (data, { resetForm }) => {
    const { email, password } = data;

    if (email === "key" && password === "key") {
      resetForm();
      localStorage.setItem("isAuthenticated", "true");
      navigate("/");
    } else {
      setErrorMessage("Invalid email or password");
    }
  };

  return (
    <section className="main-auth">
      <div className="main-auth-form">
        <h3>Sign In</h3>
        <p className="mb-0">
          Don't have an account?
          <NavLink to="/sign-up">&nbsp; Sign Up</NavLink>
        </p>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmitData}
          initialValues={{
            email: "",
            password: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
              <Form.Group controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password" className="mt-4">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              {errorMessage && (
                <div className="text-danger text-center mt-3">
                  {errorMessage}
                </div>
              )}
              <div className="mt-3">
                <NavLink to="">Forgot Password?</NavLink>
              </div>

              <Button
                type="submit"
                variant="primary"
                className="w-100 mt-4 auth-button"
              >
                Sign In
              </Button>
              <div className="or">Or</div>

              <MicrosoftAuthComponent />

              <hr/>

              <div className="message">
                Protected by reCAPTCHA and subjected to Rhombus <NavLink>Privacy Policy </NavLink> and <NavLink>Terms of Service.</NavLink>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default SignIn;
